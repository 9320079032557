.sale-process__steps-indicators {
  --swiper-theme-color: #{"" + $swiperPaginationColor};
  & > .swiper-container {
    padding-top: 1rem;
    padding-bottom: 4rem;
    .swiper-pagination {
      &-bullet {
        width: 6px;
        height: 6px;
        margin: 0 2px;
      }

      &-bullet:not(.swiper-pagination-bullet-active) {
        background: $fontColor;
        opacity: 0.8;
      }
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: $tablet) {
  .sale-process__steps-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    & > div:not(.step-indicator--active) {
      margin: 0 20px;
    }
  }
}
