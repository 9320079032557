.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $shade01;

  .input {
    background-color: $white;
  }
}
