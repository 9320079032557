.client-notaries-step {
  display: flex;
  flex-direction: column;
  @include flexGap(1rem, "column");
  margin-bottom: 1.5rem;

  > * {
    flex: 1;
  }

  + .step-actions {
    width: 100%;
    max-width: 36.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .client-and-notary {
    margin-bottom: 1.5rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid $divider;
    }
    .button {
      margin-bottom: 1.5rem;
    }

    .edit-wrapper {
      display: flex;
      @include flexGap(0.5rem, "row");

      .button--disabled {
        cursor: default;
      }

      > :first-child {
        flex: 1;
      }
    }
  }

  .add-client-and-notary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;

    .text {
      margin-bottom: 0;
    }
  }
}

.new-client-dialog,
.new-notary-dialog,
.edit-client-dialog,
.edit-notary-dialog {
  &__disclaimer {
    text-align: center;
    font-size: $miniSize;
    color: $textColor03;
  }

  .dialog__container {
    width: 100%;
  }
  .dialog__title {
    font-family: $robotoSlab;
    font-weight: $semiBold;
    font-size: 1.6rem;
  }
  .input,
  .select,
  .textarea {
    background-color: $inputBackgroundColor;
  }
}

@media screen and (min-width: $tablet) {
  .client-notaries-step {
    flex-direction: row;
    justify-content: space-between;
    @include flexGap(3rem, "row");
  }

  .new-client-dialog,
  .new-notary-dialog,
  .edit-client-dialog,
  .edit-notary-dialog {
    .dialog {
      &__container {
        width: 50rem;
      }
    }
  }
}
