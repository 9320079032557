.logout {
  .button--icon {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    border: 1px solid #e4e4db;
    color: $textColor04;
  }
}

@media screen and (min-width: $tablet) {
  .logout {
    .button--icon-only {
      height: 3rem;
      width: 3rem;
      background-color: rgba($white, 0.4);
      border: none;
      color: $white;

      &:hover,
      &:focus {
        background-color: $white;
        color: $brandSecondary;
      }
    }
  }
}
