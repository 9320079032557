.edit-user-dialog {
  &__user-status {
    font-size: $smallSize;
    color: $fontColor;
    .button {
      font-size: inherit;
      text-decoration: underline;
      letter-spacing: inherit;
      height: auto;
    }
  }
}
