$textAreaLines: 4;
$inputHeight: 3.5rem;
$inputMarginBottom: 1.5rem;
$inputFontSize: $regularSize;

.input {
  height: $inputHeight;
  width: 100%;
  font-size: $inputFontSize;
  padding: 0 1.5rem;
  margin-bottom: $inputMarginBottom;
  border: 1px solid $inputBorderColor;
  outline: none;
  background-color: $inputBackgroundColor;
  transition: background-color 0.2s, color 0.2s;
  font-family: $roboto;

  &::placeholder {
    color: $inputPlaceholder;
    font-style: italic;
  }

  &--error {
    border: 2px solid $inputError;
  }

  &--rounded {
    border-radius: 2rem;
  }

  &--textarea {
    font-family: inherit;
    resize: none;
    height: (1.6rem * $textAreaLines) + 3rem;
    padding: 1.5rem;
  }

  &--text-right {
    text-align: right;
  }

  &:read-only {
    cursor: initial;
  }

  &--filter {
    border-radius: 0.6rem;
    font-size: $smallSize;
    font-weight: 100;
    color: $fontColor;
    text-transform: uppercase;
  }

  &--grey {
    background-color: $inputBackgroundColor;

    &:hover,
    &:focus {
      background-color: $inputBackgroundColor;
    }
  }
}

input[type="time"] {
  font-family: $roboto;
}

@import "./InputCheckbox/inputCheckbox";
@import "./InputNumber/inputNumber";
@import "./InputRadio/inputRadio";
@import "./InputAutoComplete/inputAutoComplete";
@import "./InputGoogleAutoComplete/inputGoogleAutoComplete";
@import "./InputDate/InputDate";
