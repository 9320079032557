.mail-module {
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    @include flexGap(1.5rem, "row");
    margin: 3rem 0 2rem;

    .button {
      font-family: $roboto;
      font-size: $miniSize;
      padding: 0.4rem 4rem;
    }
  }

  &__history {
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 2rem;
    width: max-content;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    table-layout: fixed;

    .mail-sent-at {
      white-space: pre;
    }

    th {
      text-align: start;
      font-weight: $semiBold;
      font-size: $smallSize;
      color: $textColor02;
      padding: 1rem 2rem;

      &:first-child {
        font-weight: $bold;
        width: 55%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 25%;
      }
    }

    td {
      font-size: $smallSize;
      background-color: $shade01;
      padding: 1rem 2rem;
      font-weight: $semiBold;

      &:first-child {
        font-weight: $bold;
      }
    }

    tbody > tr {
      white-space: nowrap;
    }
  }

  &__accordion {
    .accordion-header {
      width: 100%;
      background-color: $white;
      color: $fontColor;
      font-size: 1.3rem;
      font-weight: $semiBold;
    }

    .accordion-header__toggle {
      color: $brandPrimary;
    }
  }
}

.mail-module-dialog {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include flexGap(1.5rem, "column");
    margin-bottom: 1.5rem;
    font-family: $robotoSlab;

    .icon-letter-open {
      width: 3rem;
    }
  }
}

.dialog-wrapper {
  padding: 0 2rem 2rem 2rem;
}

.template {
  width: 100%;

  &__head {
    display: flex;
    flex-direction: column;
    border: 1px solid $shade02;
    width: 100%;

    .template-docs-title {
      &__sub-label {
        font-size: 1rem;
        font-weight: $bold;
        margin-top: 0.5rem;
      }
    }

    .attachment-uploader {
      .button {
        padding: 1rem;
        width: 3rem;
        height: 3rem;
      }

      &:hover,
      &:focus {
        .button {
          background-color: $brandPrimary;
          color: $white;
        }
      }
    }

    .delete-button {
      width: 1rem;

      &:hover {
        color: $error;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $shade01;
      min-height: 5rem;
      width: 100%;
      font-size: $smallSize;

      &:nth-child(odd) {
        .row__label {
          background-color: $brandPrimary;
        }
        .row__value {
          background-color: $white;
        }
      }

      &:nth-child(even) {
        .row__label {
          background-color: $brandSecondary;
        }
        .row__value {
          background-color: $shade01;
          font-weight: $bold;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        color: $white;
        border-right: 1px solid $shade02;
        min-height: $inputHeight;
        font-family: $robotoSlab;
      }

      &__value {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem;
        width: 100%;
        color: $fontColor;
        background-color: $white;

        &:nth-child(even) {
          background-color: $shade01;
        }
      }

      input {
        margin-bottom: 0;
        padding-left: 1rem;
        text-align: start;
        font-weight: $bold;
        background-color: $shade01;

        &:placeholder {
          font-style: italic;
        }
      }
    }

    .recipients {
      .recipient {
        display: flex;
        align-items: center;
        font-weight: $bold;
        margin-right: 1rem;
        color: $fontColor;

        &:last-child {
          margin-right: 3rem;
        }
      }

      &__add-form {
        display: flex;
        flex-direction: column;

        select {
          font-size: $regularSize;
          cursor: pointer;
          margin-bottom: 1rem;
          margin-left: 0;
          width: 100%;

          &:focus-visible {
            outline: none;
          }
        }
      }

      &__custom-email {
        display: flex;

        input {
          background-color: $inputBackgroundColor;
          font-size: $regularSize;
          margin-left: 0;

          &:focus-visible {
            outline: none;
          }
        }

        .button {
          border-radius: 0;
          background-color: $inputBackgroundColor;
        }
      }

      &__add-button {
        .button {
          padding: 1rem;
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  &__content {
    border: 1px solid $shade02;
    border-top: none;
    text-align: justify;
    padding: 0 1.5rem;

    .input--textarea {
      display: flex;
      width: 100%;
      height: 20rem;
      background-color: $shade01;
      border: none;
      padding: 1rem;
      margin-bottom: 0;

      &::placeholder {
        font-style: italic;
      }

      &:focus-visible {
        outline: none;
      }
    }

    input {
      text-align: start;
      padding: 0 1rem;
    }
  }
}

@media (min-width: $tablet) {
  .template {
    &__head {
      .row {
        flex-direction: row;
        &__label {
          flex-basis: 20%;
          min-width: 20%;
        }

        &__value {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          flex-basis: 80%;
          min-width: 80%;
        }

        .recipients {
          display: inline-flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;

          &__add-form {
            flex-direction: row;

            select {
              width: 25rem;
            }
          }

          &__custom-email {
            display: flex;

            input {
              margin-bottom: 0;
              margin-left: 1rem;
              width: 15rem;
            }
          }
        }
      }
    }
  }

  .mail-module {
    &__history {
      table {
        tbody > tr {
          white-space: normal;
        }
      }
    }
  }

  .accordion-header {
    width: auto;
  }

  .mail-module {
    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;

      .button {
        width: auto;
        padding: 0.4rem 6rem;
      }

      .button:first-child {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }

    &__history {
      table {
        width: 100%;
      }
    }
  }
}

@media (min-width: $desktop) {
  .mail-module {
    &__history {
      width: 100%;
      padding: 0 5rem;
      overflow-x: hidden;

      table {
        width: 100%;
      }
    }

    &__footer {
      .button {
        width: auto;
        padding: 0.4rem 7.5rem;
      }
    }
  }

  .mail-module-dialog {
    .dialog {
      &__container {
        max-width: 90rem;

        &::-webkit-scrollbar {
          width: 0; /* Remove scrollbar space */
        }
      }
    }
  }

  .accordion {
    width: 100%;
  }
}
