.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 110rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #e2e2d6;

  .tab {
    text-transform: uppercase;
    list-style-type: none;
    display: inline-block;
    cursor: pointer;
    padding: 0 2.5rem 1.3rem;
    transition: 0.3s;
    font-size: $smallSize;
    color: rgba(59, 60, 64, 0.65);
    letter-spacing: 0.1rem;

    &--active {
      color: $fontColor;
      font-weight: $semiBold;
    }
  }

  &__cursor {
    background-color: $brandPrimary;
    height: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.3s ease-in-out;
    pointer-events: none;
  }
}
