.helper-dialog {
  .dialog__container {
    position: relative;
    max-width: 56rem;
  }

  .dialog__close {
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .dialog__title {
    padding-top: $boxSizeMargin;
    color: $saleStepColor;
    font-size: $smallSize;
    font-family: $robotoSlab;

    .icon-elio-logo-bubble {
      margin-bottom: $boxSizeMargin * 0.5;
    }

    .icon-ripple {
      color: $brandPrimary;
      width: 3.5rem;
    }
  }

  &__content {
    .text {
      margin-bottom: 1.5rem;
    }
  }

  .dialog__actions {
    justify-content: center;
    padding-top: 1.5rem;
  }
}
