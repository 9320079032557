$accordionAnimationDuration: 0.3s;

.accordion {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);

  &--opened {
    .accordion-header {
      &__toggle {
        transform: rotate(90deg);
        transition: transform 0.2s;
      }
    }
  }

  .accordion-header--disabled {
    .accordion-header__toggle {
      transform: rotate(0);
    }
  }

  .accordion-header {
    display: flex;
    padding: 1.6rem;
    background-color: $brandPrimary;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    &--disabled {
      cursor: default;
    }

    &__title {
      flex: 1;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.18ch;
    }

    &__toggle {
      display: flex;
      align-items: center;
      transition: transform $accordionAnimationDuration;
    }
  }

  .accordion-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.2rem;
  }
}

@media screen and (min-width: $tablet) {
  .accordion {
    .accordion-content {
      padding: 1.6rem;
    }

    &:hover {
      .accordion-header__toggle {
        transform: rotate(90deg);
        transition: transform 0.2s;
      }
    }
  }
}
