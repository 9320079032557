.sale-process__countdown {
  display: none;
  background-color: $shade01;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  font-family: $robotoSlab;
  font-size: $regularSize;
  text-align: center;
  color: $saleStepColor;
  font-weight: $light;
  position: relative;
  p {
    margin: 0 0 0.5rem;
  }
  &-title {
    font-size: 2.8rem;
    font-weight: $regular;
    display: block;
  }
  .icon-ripple {
    color: $brandPrimary;
  }
  &--completed {
    background: $brandPrimary;
    color: $white;
    font-size: $largeSize;
    font-weight: $regular;
    padding-top: 7rem;
    padding-bottom: 4rem;
    strong {
      font-size: 4rem;
    }
    .icon-flip-flops {
      color: $black;
      opacity: 0.1;
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media screen and (min-width: $tablet) {
  .sale-process__countdown {
    display: block;
  }
}

@media screen and (min-width: $desktop) {
  .sale-process__countdown {
    padding-top: 0;
    &-title {
      font-size: 3.4rem;
    }
  }
}
