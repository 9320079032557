@font-face {
  font-family: Roboto;
  src: local("Roboto Light"), url("./Roboto/Roboto-Light.woff2") format("woff2"),
    url("./Roboto/Roboto-Light.woff") format("woff"),
    url("./Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Light Italic"),
    url("./Roboto/Roboto-LightItalic.woff2") format("woff2"),
    url("./Roboto/Roboto-LightItalic.woff") format("woff"),
    url("./Roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto"), url("./Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Italic"),
    url("./Roboto/Roboto-Italic.woff2") format("woff2"),
    url("./Roboto/Roboto-Italic.woff") format("woff"),
    url("./Roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Medium"),
    url("./Roboto/Roboto-Medium.woff2") format("woff2"),
    url("./Roboto/Roboto-Medium.woff") format("woff"),
    url("./Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Medium Italic"),
    url("./Roboto/Roboto-MediumItalic.woff2") format("woff2"),
    url("./Roboto/Roboto-MediumItalic.woff") format("woff"),
    url("./Roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Bold"), url("./Roboto/Roboto-Bold.woff2") format("woff2"),
    url("./Roboto/Roboto-Bold.woff") format("woff"),
    url("./Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Roboto;
  src: local("Roboto Bold Italic"),
    url("./Roboto/Roboto-BoldItalic.woff2") format("woff2"),
    url("./Roboto/Roboto-BoldItalic.woff") format("woff"),
    url("./Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
}

// SLAB

@font-face {
  font-family: RobotoSlab;
  src: local("RobotoSlab Light"),
    url("./RobotoSlab/RobotoSlab-Light.woff2") format("woff2"),
    url("./RobotoSlab/RobotoSlab-Light.woff") format("woff"),
    url("./RobotoSlab/RobotoSlab-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: RobotoSlab;
  src: local("RobotoSlab"),
    url("./RobotoSlab/RobotoSlab-Regular.woff2") format("woff2"),
    url("./RobotoSlab/RobotoSlab-Regular.woff") format("woff"),
    url("./RobotoSlab/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: RobotoSlab;
  src: local("RobotoSlab Medium"),
    url("./RobotoSlab/RobotoSlab-Medium.woff2") format("woff2"),
    url("./RobotoSlab/RobotoSlab-Medium.woff") format("woff"),
    url("./RobotoSlab/RobotoSlab-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: RobotoSlab;
  src: local("RobotoSlab Bold"),
    url("./RobotoSlab/RobotoSlab-Bold.woff2") format("woff2"),
    url("./RobotoSlab/RobotoSlab-Bold.woff") format("woff"),
    url("./RobotoSlab/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
