$itemHeight: $inputHeight;
$maxItemDisplayed: 2;

.input-auto-complete {
  position: relative;

  .input--error + input {
    @extend .input--error;
  }

  .auto-complete-dropdown {
    position: absolute;
    display: none;
    top: 4rem;
    width: 100%;
    max-height: 20rem;
    overflow: auto;
    z-index: $zIndexDropdown;

    border: 1px solid $inputBorderColor;
    border-top: none;
    border-bottom: none;

    &__item,
    &__no-result {
      background: $white;
      padding: 1rem;
      cursor: pointer;
      border-bottom: 1px solid $inputBorderColor;
    }

    &__item {
      &:hover {
        background: $inputBackgroundColor;
      }
    }

    &--open {
      display: block;
    }
  }
}
