.generic-layout {
  &--full-height {
    min-height: 100vh;
  }
  &--bg-grey {
    background-color: $shade01;
  }

  .generic-layout-header {
    height: 23rem;
    position: relative;

    &--tall {
      height: 34rem;
    }

    &__gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: $headerBackgroundGradient;
      background-image: $headerBackgroundImage;
      background-size: 100% 100%;

      .header-topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 5rem;
        padding: 0.8rem 1.6rem;
        background-color: $shade01;
        box-shadow: 0px 4px 14px -1px rgba($black, 0.1);
      }

      .header-icon-container {
        display: none;
      }

      .header-title {
        color: $white;
        font-weight: $semiBold;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }

      .header-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-size: $extraLargeSize;
          text-transform: uppercase;
          text-align: center;
          font-weight: $light;
          color: $fontColor;
        }
      }

      > .icon-wave {
        color: $white;
        width: 4rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;

    &--no-padding {
      padding: 0;
    }

    &--full {
      align-items: stretch;
    }
  }
}

@media screen and (min-width: $tablet) {
  .generic-layout {
    .generic-layout-header {
      position: relative;
      height: 23rem;

      &--tall {
        height: 28rem;
        .header-content {
          height: 100%;
        }
      }

      &__actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 1.5rem;
      }

      &__gradient {
        .header-topbar {
          position: absolute;
          left: 0;
          right: 0;
          background: transparent;
          box-shadow: none;
          padding: 3rem;

          > .right-block {
            display: flex;
            align-items: center;

            .greeting {
              margin-right: 1.5rem;
            }
          }
        }

        .header-icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 6rem;
          height: 6rem;
          margin-top: 1.5rem;
          margin-bottom: 0.8rem;
          background-color: $white;
          border-radius: 50%;
          flex: none;

          .icon {
            width: 2.7rem;
            color: $brandPrimary;
          }
        }
      }
    }
  }
}
