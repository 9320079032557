$containerPadding: $boxSizeMargin;
$containerMaxWidth: $largeDesktop - $boxSizeMargin;

.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $containerMaxWidth;
  padding-left: $containerPadding;
  padding-right: $containerPadding;

  &--flex {
    display: flex;
  }

  &--no-padding {
    padding: 0;
  }
}
