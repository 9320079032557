.sale-process-acted-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include flexGap(2rem, "column");
}

.sale-process-finished-dialog {
  .dialog {
    &__close {
      background-color: $brandPrimary;

      .icon-close {
        background-color: $brandPrimary;
      }
    }

    &__container {
      max-width: 45rem;
    }
  }
  .congratulations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 2rem 2rem;
    background-color: $brandPrimary;
    position: relative;

    .icon-flip-flops {
      position: absolute;
      top: -1.8rem;
      opacity: 0.2;
      z-index: 1;
    }

    .text {
      z-index: 2;
    }
  }

  .tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    @include flexGap(1rem, "column");

    &__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.75rem;
      height: 3.75rem;
      padding: 0.75rem;
      border: 1px solid $disabledColor;
      border-radius: 50%;
    }
  }
}

@media screen and (min-width: $tablet) {
  .sale-process-acted-step {
    flex-direction: row;
    align-items: inherit;

    .sale-process-card {
      width: 100%;
    }
  }
}

@media screen and (min-width: $desktop) {
  .sale-process-acted-step {
    @include flexGap(2rem, "row");
  }
}
