.document-module-dialog {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include flexGap(1.5rem, "column");
    margin-bottom: 1.5rem;
    font-family: $robotoSlab;

    .icon-document-download {
      width: 5rem;
    }
  }

  &__title {
    font-weight: $light;
  }

  &__download-actions {
    display: flex;
    @include flexGap(2rem, "column");
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem 0;
    margin-bottom: 2rem;
    width: 100%;
    white-space: pre;

    > * {
      width: 20rem;
      padding: 0;
    }

    .file-uploader {
      &:hover,
      &:focus {
        .button {
          @extend %primary-hover;
        }
      }
    }
  }

  .dialog {
    &__container {
      &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
      }
    }

    &__content {
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
      }
    }
  }

  .select-wrapper {
    width: 30rem;
    text-align: center;

    .select {
      border: 1px solid $brandPrimary;
      background-color: $white;
      border-radius: 2rem;
      color: $brandPrimary;
      text-transform: uppercase;
      text-align-last: center;
      font-size: $smallSize;

      option {
        color: $fontColor;
      }
    }

    &::after {
      border-top-color: $brandPrimary;
      right: 2rem;
    }
  }

  .accordion {
    box-shadow: none;
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;
    margin-bottom: 2rem;

    &-content {
      padding: 0 2rem 2rem;

      .document-selection-actions {
        display: flex;
        flex-direction: column;
        @include flexGap(1.5rem, "column");
        width: 100%;
        margin-bottom: 2rem;
      }

      .document-list {
        overflow-x: auto;
        width: 100%;

        table {
          width: 100%;
        }

        thead {
          th {
            text-align: left;
            font-weight: $regular;
            padding-bottom: 0.5rem;
          }
        }

        .document {
          background-color: $shade01;

          &__size,
          &__type {
            white-space: nowrap;
          }

          &__checkbox {
            .label::before {
              top: 0.3rem;
            }

            .checkbox:checked {
              border: 1px solid #c3c3c3;
            }
          }

          td {
            vertical-align: middle;
            padding: 0.5rem 1rem;
          }

          .checkbox-label {
            display: flex;
            align-items: center;

            .icon-paper {
              margin-right: 1rem;
            }

            label {
              max-width: 25rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; // max nb lines to show
              -webkit-box-orient: vertical;
            }
          }

          &__actions {
            display: flex;
            justify-content: center;
            @include flexGap(1.5rem, "row");

            .button {
              padding: 0;
              height: 3rem;
            }
          }
        }
      }
    }

    &-header {
      background-color: transparent;

      &__title {
        color: $fontColor;
        font-weight: $semiBold;
        font-size: $smallSize;
      }

      &__toggle {
        color: $brandPrimary;
      }
    }
  }
}

.delete-confirmation-dialog {
  .dialog {
    &__content {
      margin: auto 0;
    }

    &__container {
      max-width: 50rem;
      height: 20rem;
    }

    &__actions {
      > .button:first-child {
        border: none;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .document-module-dialog {
    &__download-actions {
      flex-direction: row;
      @include flexGap(2rem, "row");
      padding: 2rem 4rem 0;
    }

    .accordion {
      &-content {
        .document-selection-actions {
          flex-direction: row;
          @include flexGap(1.5rem, "row");
          margin-bottom: 1rem;
        }

        .document-list {
          display: block;
          justify-content: inherit;
          width: 100%;
        }
      }
    }
  }
}
