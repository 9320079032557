.contact-module-dialog {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include flexGap(1.5rem, "column");
    margin-bottom: 1.5rem;
    font-family: $robotoSlab;

    .icon-address-book {
      width: 5rem;
    }
  }

  .dialog {
    &__content {
      display: flex;
      flex-direction: column;
      @include flexGap(2rem, "column");
      margin-top: 2rem;
    }

    &__actions {
      justify-content: center;

      .button {
        width: auto;
        padding: 0 5rem;
      }
    }
  }

  .clients-and-notaries {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: fit-content;
    border: 1px solid $shade02;
    margin-right: 1.3rem;

    &:after {
      content: "";
      position: absolute;
      right: -1.3rem;
      top: -1px;
      width: 1.3rem;
      height: 8rem;
      border-bottom-right-radius: 1.3rem;
      background: linear-gradient(
        270deg,
        $primaryGradientColor02 0%,
        $primaryGradientColor01 100%
      );
    }

    .client-and-notary {
      display: flex;
      flex-direction: column;

      .contact-informations,
      .partner-agency-informations {
        &__title {
          background-color: $shade01;
          padding: 1rem 2rem;
          text-transform: uppercase;
          font-weight: $semiBold;
        }

        &__content {
          display: flex;
          flex-direction: column;
          @include flexGap(1rem, "column");
          padding: 1rem 2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .contact-module-dialog {
    .dialog {
      &__content {
        flex-direction: row;
        @include flexGap(4rem, "row");
      }
    }
  }
}
