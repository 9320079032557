.search-input-text {
  width: 100%;
  display: flex;

  div:first-child {
    border: solid 2px $inputBorderColor;
    border-right: 0;
    border-radius: 0.6rem 0 0 0.6rem;
    padding-left: 1.1rem;
    padding-bottom: 0.2rem;
    width: 3rem;
    height: 4rem;
    color: $brandSecondary;
  }

  input {
    -webkit-appearance: none;
    height: 4rem;
    font-size: $regularSize;
    font-weight: $semiBold;
    letter-spacing: 0.1ch;
    border: 2px solid $shade01;
    border-radius: 0 0.6rem 0.6rem 0;
    border-left: none;
    &::placeholder {
      font-style: normal;
      font-size: $regularSize;
      font-weight: $semiBold;
      letter-spacing: 0.1ch;
    }
  }
}
