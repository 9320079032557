.general {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include flexGap(3rem, "column");
    width: 100%;
    max-width: 125rem;
    margin-bottom: 4rem;
  }

  .general-card {
    flex: 1;
    padding: 2rem 3rem;
    box-shadow: $cardShadow01;

    &__title {
      display: flex;
      align-items: center;
      @include flexGap(0.5rem, "row");
    }
  }
}

@media screen and (min-width: $tablet) {
  .general {
    &-card {
      padding: 2rem 3rem;
    }

    &__container {
      flex-direction: row;
      @include flexGap(2rem, "row");
    }
  }
}

@media screen and (min-width: $largeDesktop) {
  .general {
    &-card {
      padding: 2rem 4rem;
    }
  }
}
