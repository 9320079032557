.radio-group {
  display: flex;
  align-items: center;
  @include flexGap(1.5rem, "row");
  margin-bottom: 1.5rem;

  &__radio-container {
    display: flex;
    @include flexGap(1.5rem, "row");
  }
}
