.contact-directory {
  &__tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4rem 0;
    background-color: $shade01;

    .tabs {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        li {
          margin: 0 5rem;
        }
      }
    }

    .select {
      border: 1px solid $shade04;
      background-color: $white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__title {
    padding-top: 3rem;
    font-family: $robotoSlab;
    color: $textColor02;
  }

  &__fetch-more {
    margin-bottom: 3rem;

    .button {
      font-weight: $semiBold;
    }
  }
}

.contact-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  margin: 3rem 0;
  padding: 0 3rem;
  width: 100%;

  .contact-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: $cardShadow01;
    background-color: $white;
    padding: 3.5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      box-shadow: $cardShadow02;
    }

    &__name {
      font-size: $mediumSize;
      font-weight: $bold;
      margin-bottom: 2rem;

      span:first-child {
        text-transform: capitalize;
      }

      span:last-child {
        text-transform: uppercase;
        margin-left: 0.75rem;
      }
    }

    &__data {
      font-weight: $semiBold;
      font-size: $smallSize;
    }
  }
}

.tab-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__search {
    margin: 1rem 0;

    input {
      background-color: transparent;
      border: 2px solid $shade04;
      border-left: 0;
    }
  }

  .search-input-text {
    div:first-child {
      background-color: transparent;
      border: 2px solid $shade04;
      border-right: 0;
    }
  }

  &__create {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 4rem;

      div:first-child {
        margin-left: 1rem;
      }
    }
  }

  &__label {
    font-family: $robotoSlab;
    color: $textColor02;
    padding-bottom: 1rem;
  }
}

.contact-directory-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $brandPrimary;
    font-size: $mediumSize;
    font-family: $robotoSlab;
    text-transform: uppercase;
    margin: 2rem 0;

    span:first-child {
      padding-bottom: 0.5rem;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @include flexGap(1rem, "row");
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (min-width: $largeMobile) {
  .contact-directory-form {
    width: 100%;
  }

  .clients-notaries-dialog {
    .dialog__container {
      width: 100%;
    }
  }
}

@media (min-width: $tablet) {
  .contact-directory-form {
    width: 40rem;
  }

  .contact-directory {
    width: 100%;
  }

  .contact-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .tab-content {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    &__search {
      margin: 0;
    }
  }

  .clients-notaries-dialog {
    .dialog__container {
      width: auto;
      height: initial;
    }
  }
}

@media (min-width: $desktop) {
  .contact-cards {
    padding: 0 4rem;
    grid-template-columns: repeat(3, 1fr);
  }

  .tab-content {
    width: 95rem;
  }
}

@media (min-width: $largeDesktop) {
  .contact-directory {
    width: auto;
  }

  .contact-cards {
    padding: 0 6rem;
    grid-template-columns: repeat(4, 1fr);
  }

  .tab-content {
    width: 110rem;
  }
}
