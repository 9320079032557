.grid {
  display: flex;
  gap: 0 $boxSizeMargin * 0.5;
  &__col {
    flex: 1;
  }
  &--vertical {
    flex-direction: column;
    gap: $boxSizeMargin * 0.5 0;
  }
}
