.dashboard {
  width: 100%;
  max-width: 31.5rem;
  margin-bottom: 2rem;

  &__no-sales {
    text-align: center;
  }

  &-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include flexGap(1rem, "column");
    margin-bottom: 2rem;
    width: 100%;

    .input,
    .select {
      margin-bottom: 0;
    }

    .toggle-filters-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .accordion {
      width: 100%;
      box-shadow: none;

      &-header {
        display: none;
      }

      &-content {
        @include flexGap(1rem, "column");
        padding: 0;
      }
    }

    .select,
    .input {
      background-color: $white;
    }

    .button {
      margin-right: 1rem;
    }

    .button--rounded-to-full-width-animation {
      margin-bottom: 1.5rem;
    }

    .select {
      height: 4rem;
      font-size: $regularSize;
      font-weight: $semiBold;
      letter-spacing: 0.1ch;
      border: 2px solid $shade01;
      &::placeholder {
        font-style: normal;
        font-size: $regularSize;
        font-weight: $semiBold;
        letter-spacing: 0.1ch;
      }
    }
    .select-wrapper {
      &:after {
        top: 1.8rem;
      }
    }

    &__search {
      display: flex;
      width: 100%;
    }
  }

  &__sales {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin-bottom: 3rem;
  }

  &__sale {
    width: 100%;
  }

  &__fetch-more {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      font-size: $smallSize;
    }
  }

  &__business-offer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 22rem;
    box-shadow: $cardShadow01;
    background-color: $white;

    .text {
      margin: 7rem 0 3.5rem 0;
    }

    .button {
      height: 3.75rem;
      font-size: 1.5rem;
    }

    .link {
      color: $white;
      font-weight: $regular;
    }

    a {
      padding: 1.2rem 2.5rem;
    }
  }
}

.dashboard-header {
  display: flex;
  justify-content: center;

  &__number {
    font-weight: $bold;
    font-size: 7.5rem;
    margin-right: 1.5rem;
  }

  &__text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    font-weight: 100;
    font-size: 3rem;
    text-transform: uppercase;
  }
}

.draft-sale-completed-dialog {
  .dialog {
    &__container {
      max-width: 50rem;
    }
  }
}

.trial-period-dialog {
  .dialog {
    &__container {
      max-width: 50rem;
    }

    &__close {
      background-color: $brandPrimary;
    }

    &__title {
      background-color: $brandPrimary;
      padding: 2rem 0;
      margin-bottom: 2rem;
    }
  }

  .icon {
    background-color: $brandPrimary;
  }
}

@media (min-width: $tablet) {
  .dashboard {
    max-width: none;
    width: auto;
    &-filters {
      width: auto;
      .dashboard-filters__search,
      .dashboard-filters__search ~ * {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
    &-filters--collapsed {
      .dashboard-filters__search,
      .dashboard-filters__search ~ * {
        visibility: hidden;
        opacity: 0;
      }
    }
    .button--rounded-to-full-width-animation {
      margin-bottom: 0;
      &:hover {
        max-width: 21rem;
      }
    }
    &__sales {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .business-offer-dialog {
    .dialog__container {
      height: auto;
    }
  }
}

@media (min-width: $desktop) {
  .dashboard {
    &__sales {
      grid-template-columns: repeat(3, 1fr);
    }

    &__sale {
      width: 100%;
    }

    &-filters {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      @include flexGap(1rem, "row");
      padding: 0 8rem;

      .toggle-filters-container {
        .button--icon-only {
          display: none;
        }
      }
    }
  }
}

@media (min-width: $largeDesktop) {
  .dashboard {
    width: auto;
    &__sales {
      grid-template-columns: repeat(4, 1fr);
      min-width: 139rem;
    }
  }
}
