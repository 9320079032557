.input-date-dialog {
  .dialog {
    &__close {
      display: none;
    }
    &__content,
    &__container {
      background-color: transparent;
    }

    &__container {
      width: fit-content;
    }

    &__content {
      padding: 0;
    }
  }

  .react-datepicker__month--selected,
  .react-datepicker__year--selected {
    background-color: $white;
  }

  .react-datepicker {
    &__year-wrapper {
      max-width: 28rem;
    }

    &__header {
      background-color: $white;
      border-bottom: none;
      color: $fontColor;
      font-weight: 400;
      padding: 0 0 1rem 0;

      .react-datepicker__current-month--button {
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: $shade01;
        }
      }

      &--top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;

        button {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: $regularSize;
          border: none;
          padding: 0 1.5rem;
          background-color: transparent;

          &:hover {
            background-color: $shade01;
          }
        }
      }
    }

    &__day.react-datepicker__day--outside-month {
      color: transparent;
      background: transparent;
      cursor: default;
      pointer-events: none;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text,
    .react-datepicker__year .react-datepicker__year-text,
    &__current-month,
    &__day-name,
    &__day,
    &__time-name {
      line-height: $largeSize * 2;
      font-size: $smallSize;
      margin: 0;
      border-radius: 0;
      color: $fontColor;
      font-weight: 400;
    }

    .react-datepicker__year .react-datepicker__year-text {
      width: 7rem;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
      width: 9.3rem;
    }

    .react-datepicker__month-text,
    &__day-name,
    &__day,
    &__time-name {
      width: $largeSize * 2;
      border: 1px solid $white;
    }

    &__day:hover,
    &__month-text:hover,
    &__quarter-text:hover,
    &__year-text:hover {
      background-color: $white;
      border: 1px solid $brandPrimary;
      border-radius: 0;
    }

    &__day--selected,
    &__day--selected:hover,
    &__day--keyboard-selected,
    &__day--keyboard-selected:hover,
    .react-datepicker__month
      .react-datepicker__month-text.react-datepicker__month--selected:hover,
    .react-datepicker__month .react-datepicker__month-text--selected,
    .react-datepicker__month .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__year .react-datepicker__year-text--selected,
    .react-datepicker__year
      .react-datepicker__year-text.react-datepicker__year--selected:hover {
      background-color: $brandPrimary;
      border: 1px solid $brandPrimary;
      color: $white;
    }

    &__day--disabled,
    &__month--disabled,
    &__year-text--disabled {
      background-color: $shade01;

      &:hover {
        background-color: $shade01;
        border: none;
      }
    }
  }
}

.input-date {
  width: 100%;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-top: 0.6rem solid $inputArrow;
    right: 1.2rem;
    top: 1.5rem;
  }
}
