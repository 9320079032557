.module-dialog {
  .dialog__container {
    position: relative;
  }

  .dialog__title {
    color: $saleStepColor;
    font-size: $smallSize;
    font-family: $robotoSlab;

    .icon {
      margin-bottom: 1rem;
      color: $brandPrimary;
      width: 5rem;
    }

    .icon-ripple {
      margin-top: 1rem;
      width: 3rem;
    }
  }

  &__content {
    color: $saleStepColor;
    font-size: $smallSize;
    padding-bottom: $boxSizeMargin;
  }

  &--no-ripple {
    .dialog__title {
      .icon-ripple {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .module-dialog {
    .dialog {
      &__container {
        max-width: 60rem;
      }
    }
  }
}
