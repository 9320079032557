.business-offer-dialog {
  .dialog {
    &__container {
      max-width: 50rem;
    }

    &__close {
      background-color: $brandPrimary;
    }

    &__title {
      background-color: $brandPrimary;
      padding: 2rem 0;
      margin-bottom: 2rem;
    }
  }

  .icon {
    background-color: $brandPrimary;
  }

  .dialog__actions {
    a {
      padding: 1rem 3.5rem;
    }
  }
}
