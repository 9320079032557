.sale-process-documents-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include flexGap(2rem, "column", "false");
}

.documents-dialog {
  .dialog__container {
    max-width: 54rem;
  }
  .dialog__container {
    height: auto;
  }
  .dialog__title {
    .text:nth-child(2) {
      font-size: 1.3rem;
      letter-spacing: 0.03ch;
    }
    .icon-ripple {
      width: 3.5rem;
      margin-bottom: 1rem;
    }
  }
  .sale-process-checklist {
    margin-top: 1.5rem;

    .button {
      -webkit-text-fill-color: $fontColor;
      color: $fontColor;
    }
  }

  .dialog__actions {
    padding-top: 3rem;
    padding-bottom: 3.5rem;
  }
}

@media screen and (min-width: $tablet) {
  .sale-process-documents-step {
    align-items: inherit;
  }

  .documents-dialog {
    .dialog__title {
      padding: 0 4rem 0;
    }

    .dialog__actions {
      width: auto;

      .button {
        width: 100%;
      }
    }
  }

  .sale-process-documents-step {
    flex-direction: row;
  }
}

@media screen and (min-width: $desktop) {
  .sale-process-documents-step {
    .sale-process-card {
      width: 40rem;
      &__actions {
        margin-top: 1rem;
      }
    }
  }
}
