@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba($white, 0.9);
  z-index: $zIndexSpinner;
  display: flex;
  align-items: center;
  justify-content: center;

  &--fixed {
    position: fixed;
  }

  .icon {
    animation: spin 1s infinite steps(64);
    color: $brandPrimary;
  }
}
