$zIndexDropdown: 80;
$zIndexMenuOverlay: 90;
$zIndexSaleProcessMenu: 10;
$boxSizeMargin: 24px;
$zIndexMenu: 100;
$zIndexSpinner: 110;

/**
 * Nothing should be higher than this 3 values.
 */
$zIndexDialogPortal: 1000;
$zIndexDialogContainer: $zIndexDialogPortal - 1;
$zIndexDialogOverlay: $zIndexDialogContainer - 1;

/* Animations */
$shorterDuration: 200ms;
$defaultDuration: 300ms;
$defaultTransition: $defaultDuration ease;

@import "react-toastify/dist/ReactToastify.css";
@import "swiper/swiper.scss";
@import "swiper/components/pagination/pagination.scss";
@import "react-big-calendar/lib/css/react-big-calendar.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@import "./normalize";

@import "./mixins";
@import "./components/components";
@import "./pages/pages";
