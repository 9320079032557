.planning-module-dialog {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include flexGap(1.5rem, "column");
    margin-bottom: 1.5rem;
    font-family: $robotoSlab;

    .icon-planning {
      width: 5rem;
    }
  }

  &__legends {
    display: inline-flex;
    @include flexGap(1.5rem, "row");
    padding: 0 2rem 2rem;

    .legend {
      display: flex;
      align-items: center;
      @include flexGap(1rem, "row");

      &__square {
        &--grey {
          min-width: 2rem;
          min-height: 2rem;
          background-color: #ccc;
        }

        &--primary {
          min-width: 2rem;
          min-height: 2rem;
          background-color: $brandPrimary;
        }
      }
    }
  }

  &__availabilities {
    display: flex;
    flex-direction: column;
    @include flexGap(1rem, "column");
    width: 100%;

    &-text {
      padding: 2rem 2rem 0;
    }
  }

  &__availabilities-list {
    display: flex;
    flex-direction: column;
    @include flexGap(0.3rem, "column");
    width: 100%;
    padding-left: 0.2rem;
  }

  &__availability-item {
    display: flex;
    align-items: center;
    @include flexGap(0.5rem);
    width: 100%;
    background-color: $shade01;
    padding: 1rem 0.5rem 1rem 1.8rem;
  }

  .planning-module {
    &__toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__right-block {
    flex: 1;
  }

  .dialog {
    &__content {
      display: flex;
      flex-direction: column;
      @include flexGap(1.5rem, "column");
      padding: 0;
      margin: 0 1rem 1rem;
      border: 1px solid #ddd;
    }
  }

  .rbc-calendar {
    position: relative;

    .rbc-events-container {
      margin-right: 0;
    }

    .rbc-time-content {
      border-top: none;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .rbc-time-view {
      border: none;
      padding-right: 1.5rem;
    }

    .rbc-time-header-content {
      border-left: none;
    }

    .rbc-header {
      border-left: none;
    }

    /* hide sunday */
    .rbc-header:last-of-type {
      display: none;
    }

    /* hack to create a ghost row at the top of the calendar to respect the mock-up  */
    .rbc-timeslot-group {
      &:first-of-type {
        position: relative;
        &:before {
          content: "";
          width: 100%;
          height: 2rem;
          position: absolute;
          top: -2rem;
          left: -1px;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }

    /* see https://projets.occitech.fr/issues/17177 to see why this is necessary */
    .rbc-time-column:not(:nth-last-child(2)) {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 1px;
        width: 100%;
        height: 1rem;
      }
    }

    .rbc-time-gutter {
      .rbc-timeslot-group {
        .rbc-time-slot {
          position: relative;
          min-width: 6rem;

          .rbc-label {
            position: absolute;
            top: -0.65rem;
            right: 0.9rem;
            padding-left: 3rem;
            font-size: $miniSize;
          }
        }

        /* hack again, this time to show the last hour of the planning */
        &:last-of-type {
          &:after {
            content: "20:00";
            position: absolute;
            bottom: 1.3rem;
            background: white;
            padding-left: 1.55rem;
            padding-right: 0.5rem;
            font-size: $miniSize;
          }
        }
      }
    }

    .rbc-time-gutter {
      .rbc-time-slot {
        .rbc-label {
          background-color: $white;
        }
      }
    }

    .rbc-event {
      border-radius: 0;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid $white;
      background-color: #ccc;
      padding: 0.1rem;

      &.common-slot {
        background-color: $brandPrimary;

        .owners {
          display: none;
        }
      }
    }

    .rbc-event-label,
    .rbc-allday-cell {
      display: none;
    }

    .rbc-today {
      background-color: initial;
    }

    .rbc-current-time-indicator {
      display: none;
    }

    .week-header {
      padding: 0.5rem 0;

      display: flex;
      flex-direction: column;
      @include flexGap(0.3rem, "column");

      &__day-text {
        font-weight: $regular;
        text-transform: uppercase;
        font-size: $extraSmallSize;
      }

      &__day-numeric {
        font-size: $mediumSize;
      }
    }

    .custom-event {
      .owners {
        display: flex;
        flex-wrap: wrap;
        .owner-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 1.4rem;
          height: 1.4rem;
          font-size: 0.6rem;
          border: 1px solid $divider;
          border-radius: 50%;
          padding: 0.1rem;
          background-color: $textColor04;
        }
      }
    }
  }
}

.select-owners-dialog {
  user-select: none;

  .dialog {
    &__container {
      user-select: none;
      max-width: 25rem;
    }

    &__content {
      padding: 2rem 2rem 0;

      .divider {
        margin-top: 0.8rem;
      }
    }

    &__actions {
      justify-content: center;
    }
  }

  &__owners {
    display: flex;
    flex-direction: column;
    @include flexGap(1.25rem, "column");

    .checkbox-wrapper {
      .checkbox:checked + span.label {
        font-weight: $bold;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .planning-module-dialog {
    .planning-module {
      &__toolbar {
        position: absolute;

        left: 4.4rem;
        right: 0;
      }
    }

    .dialog {
      &__content {
        flex-direction: row;
        @include flexGap(0, "row");
        margin: 0 2rem 2rem;
      }
    }

    .rbc-calendar {
      padding: 1rem 5rem 1rem 4rem;

      .rbc-time-column:last-of-type {
        display: none;
      }

      .rbc-time-header-gutter {
        border-bottom: 1px solid #ddd;
      }

      .rbc-time-view {
        padding-right: 0;
      }

      .rbc-event {
        padding: 0.2rem;
      }

      .custom-event {
        .owners {
          .owner-tag {
            min-width: 2.3rem;
            height: 2.3rem;
            font-size: 0.8rem;
            padding: 0.5rem;
          }
        }
      }

      .rbc-time-gutter {
        .rbc-timeslot-group {
          &:last-of-type {
            &:after {
              bottom: -0.7rem;
              padding-left: 1.7rem;
            }
          }
        }
      }

      .rbc-time-column:not(:nth-last-child(2)) {
        &:after {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
}

@import "./PlanningCalendar/planningCalendar";
