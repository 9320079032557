.forgot-password-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem 3rem;
  width: 100%;
  max-width: 55rem;

  b {
    text-align: center;
    white-space: pre-line;
  }

  .input {
    text-align: center;
    height: 4rem;

    &::placeholder {
      font-style: italic;
    }
  }

  .button {
    margin: 2rem 0 2rem;
    padding: 0 3rem;
    font-size: $regularSize;
    height: 4rem;
  }

  .link {
    color: $shade03;
    margin-bottom: 1rem;
    font-style: normal;
    font-weight: $regular;
  }
}

@media screen and (min-width: $tablet) {
  .login-form {
    padding: 3rem;
  }
}
