.logo {
  .icon {
    color: $white;
    width: 16.4rem;
  }
}

@media screen and (min-width: $desktop) {
  .logo {
    .icon {
      width: 35rem;
    }
  }
}
