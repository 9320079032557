.document-step {
  &,
  & + .step-actions {
    width: 100%;
    max-width: 36.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .offer-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > * {
      flex: 1;
    }
  }

  .file-uploader {
    .button {
      text-transform: uppercase;
    }

    &:not(&--disabled) {
      &:hover,
      &:focus {
        .button {
          @extend %reversed-primary-hover;
        }
      }
    }

    &--disabled {
      .button {
        @extend .button--disabled;
      }
    }
  }

  .document-list {
    width: 100%;
    margin-bottom: 3rem;

    .document {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__name-wrapper {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        overflow: hidden;

        .icon {
          margin-right: 1rem;
        }
      }

      &__name {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__action {
        .button {
          height: 3rem;
          width: 3rem;
        }
      }
    }
  }
}
