.sale-process-checklist {
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    padding: 0.75rem;
    background-color: #fbeae8;

    &--is-checked {
      background-color: $shade01;
    }

    .icon-bin {
      min-width: 1.2rem;
      width: 1.2rem;
    }

    .checkbox-wrapper {
      overflow-x: hidden;

      .label {
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .checkbox:checked + .label::before {
        top: 0.4rem;
      }
    }
  }

  &__checkbox-label {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    white-space: pre;

    .icon {
      margin-right: 0.75rem;
    }
  }

  &__input {
    display: flex;
    background-color: $shade01;
    padding-left: 0.75rem;

    .checkbox-wrapper {
      .label {
        margin-left: 1rem;
      }

      .checkbox {
        margin-right: 1rem;
      }
    }

    .input {
      margin-bottom: 0;
      padding-left: 0.75rem;
      border: none;
      background-color: $shade01;
    }
  }

  &__check-all {
    margin-bottom: 0.75rem;
  }
}
