$cardPadding: 2.5rem 4rem;

.card {
  background-color: $white;
  width: 100%;
  max-width: 100%;
  box-shadow: $cardShadow03;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: $cardPadding;
}
