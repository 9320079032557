.security {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  @include flexGap(1rem, "column");
  padding: 2.5rem;
  box-shadow: $cardShadow01;

  .icon-ripple {
    margin-bottom: 1rem;
  }

  .button {
    padding: 0 5rem;
    margin-top: 2rem;
  }
}

.password-dialog {
  &__fields {
    width: 100%;
  }

  &__legend {
    color: $textColor04;
  }

  .labelled-field {
    &:first-of-type {
      margin-bottom: 2rem;
    }

    &:not(:first-of-type) {
      margin-bottom: 0.75rem;
    }

    &__field {
      flex: 1;
    }
  }

  .dialog {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include flexGap(2rem, "column");
    }

    &__actions {
      justify-content: center;

      .button {
        padding: 0 4rem;
      }
    }

    &__container {
      max-width: 65rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .security {
    padding: 2.5rem 5rem;
  }

  .password-dialog {
    &__fields {
      width: initial;
    }
    &__legend {
      max-width: 40rem;
    }
    .dialog {
      &__actions {
        .button {
          padding: 0 5rem;
        }
      }
    }
  }
}
