.link {
  text-decoration: none;
  color: $linkColor;
  font-style: italic;
  font-weight: $semiBold;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
