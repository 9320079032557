.sale-process-card {
  padding: 0 1rem;
  max-width: 45rem;
  width: 100%;

  .card {
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  &__button {
    position: absolute;
    right: 0;
    top: 0;
    color: $divider;

    &:hover,
    &:focus {
      .icon {
        color: $brandPrimary;
      }
    }

    &--alert {
      &:hover,
      &:focus {
        .icon {
          color: $brandTertiary;
        }
      }
    }
  }

  &__title {
    text-transform: uppercase;
    color: $saleStepColor;
    font-size: $smallSize;
    font-family: $robotoSlab;
    margin: 0;
  }

  .icon-ripple {
    color: $brandPrimary;
    min-width: 4rem;
    width: 4rem;
    margin: 1rem 0 1.5rem;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__content {
    width: 100%;
    font-family: $robotoSlab;
    font-weight: $light;
    margin-bottom: 1.5rem;
  }

  &__actions {
    display: flex;
    justify-content: center;
    @include flexGap(1rem, "column");
    flex-wrap: wrap;

    .button {
      white-space: pre;
    }
  }
}

@media screen and (min-width: $largeMobile) {
  .sale-process-card {
    &__actions {
      flex-wrap: nowrap;
      @include flexGap(1rem, "row");
    }
  }
}

@media screen and (min-width: $tablet) {
  .sale-process-card {
    width: 45%;
    max-width: auto;
    .card {
      padding: 2.5rem 2.5rem;
    }

    &__actions {
      @include flexGap(1rem, "row");
    }
  }
}

@media screen and (min-width: $desktop) {
  .sale-process-card {
    width: 30%;
  }
}
