.statistics-card {
  width: 100%;
  box-shadow: $cardShadow02;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 2rem 0;
  border-radius: 0.75rem;

  .input-date {
    .react-datepicker {
      position: absolute;
      left: -1rem;
      top: -2rem;
    }
  }

  &__header {
    height: 5.5rem;

    h3 {
      color: $textColor01;
      text-transform: uppercase;
      font-size: $regularSize;
      font-weight: $semiBold;
      margin: 0 0 2rem 0;

      .icon-wave {
        display: block;
        font-size: small;
        margin: 1rem auto auto auto;
        position: relative;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 11rem;

    .stat-realised {
      height: 100%;
      border-right: 1px solid rgba(255, 255, 255, 0.75);

      &__title {
        font-weight: $semiBold;
        letter-spacing: 0.05em;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 1.5rem;
      }

      &__nb {
        font-size: $extraLargeSize;
        font-weight: $bold;
        color: #3b3c40;
      }

      &__icon {
        margin-top: 1.75rem;
      }
    }

    .stat-realised__nb.last-card {
      display: flex;
      justify-content: center;
      align-items: center;

      div:first-child {
        margin-right: 2rem;
      }

      div:last-child {
        margin-left: 2rem;
      }
    }

    .stat-realised__mention {
      color: $white;
      font-size: $miniSize;
      font-weight: $semiBold;
      text-transform: uppercase;
      padding: 0.25rem 0 0.75rem 0;
    }
    .stat-realised__mention.last-card {
      color: $textColor00;
      font-weight: $semiBold;
    }
  }

  .statistics-monthselect {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .select {
      width: 100%;
      border-radius: 0.25rem;
    }

    .select-wrapper {
      width: 55%;
    }
  }

  &:nth-child(1),
  &:nth-child(3) {
    background: $primaryBackgroundGradient;
  }

  &.white-card {
    background: $white;

    .stat-realised {
      border-right: 2px solid $shade04;

      &__title {
        color: $brandPrimary;
      }

      &__mention {
        color: $textColor00;
      }
    }
  }
}

@media (min-width: $tablet) {
  .statistics-card {
    width: 40rem;

    .input-date {
      .react-datepicker {
        position: inherit;
      }
    }
  }
}

@media (min-width: $desktop) {
  .statistics-card {
    width: 35rem;
  }
}
