.choose-sale-type {
  &__title .text {
    margin-top: 1.2rem;
    line-height: $mediumLineHeight;
    letter-spacing: 0.03ch;
    font-size: 2.2rem;
    b {
      font-weight: $bold;
      letter-spacing: 0.01ch;
    }
  }
  .sale-types-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include flexGap(2rem, "column");
    margin: 5rem 0 4.5rem;

    .sale-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20rem;
      height: 20rem;
      background: linear-gradient(
        45deg,
        rgba(#0ae0ad, 1) 0%,
        rgba(#43edea, 1) 100%
      );
      border-radius: 100%;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include flexGap(1rem, "column");
        width: 17rem;
        height: 17rem;
        background: $white;
        border-radius: 100%;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__content {
    letter-spacing: 0.02ch;
    p {
      margin-bottom: 1rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .choose-sale-type {
    &__title .text {
      font-size: 2.6rem;
    }
    .sale-types-wrapper {
      flex-direction: row;
      @include flexGap(4rem, "row");
    }
  }
}
