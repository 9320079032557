.text {
  font-size: $regularSize;

  &--color {
    &-default {
      color: $fontColor;
    }

    &-primary {
      color: $textColorPrimary;
    }

    &-secondary {
      color: $brandSecondary;
    }

    &-dark {
      color: $textColor00;
    }

    &-disabled {
      color: $disabledColor;
    }

    &-white {
      color: $white;
    }

    &-grey {
      color: $textColor02;
    }
  }

  &--light {
    font-weight: $light;
  }

  &--light {
    font-weight: $light;
  }

  &--regular {
    font-weight: $regular;
  }

  &--semi-bold {
    font-weight: $semiBold;
  }

  &--bold {
    font-weight: $bold;
  }

  &--size {
    &-regular {
      font-size: $regularSize;
    }

    &-mini {
      font-size: $miniSize;
    }

    &-small {
      font-size: $smallSize;
    }

    &-medium {
      font-size: $mediumSize;
    }

    &-large {
      font-size: $largeSize;
    }

    &-very-large {
      font-size: $veryLargeSize;
    }

    &-extra-large {
      font-size: $extraLargeSize;
    }
  }

  &--align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }

    &-justify {
      text-align: justify;
    }
  }

  &--font {
    &-roboto {
      font-family: $roboto;
    }

    &-roboto-slab {
      font-family: $robotoSlab;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--italic {
    font-style: italic;
  }

  &--line-height {
    &-default {
      line-height: $defaultLineHeight;
    }

    &-medium {
      line-height: $mediumLineHeight;
    }

    &-large {
      line-height: $largeLineHeight;
    }
  }

  &--margin-bottom {
    &-default {
      margin-bottom: inherit;
    }

    &-medium {
      margin-bottom: 1rem;
    }

    &-large {
      margin-bottom: 2rem;
    }
  }

  &--appearance {
    &-input {
      @extend .input;

      display: flex;
      align-items: center;
      color: $textColor00;
    }
  }
}
