.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem 3rem;
  width: 100%;
  max-width: 55rem;

  .input {
    text-align: center;
    height: 4rem;
    background-color: $white;

    &::placeholder {
      font-style: italic;
    }
  }

  .link {
    color: $loginFormLinkColor;
    margin-bottom: 1.5rem;
    font-style: normal;
    font-weight: $regular;
    font-size: $smallSize;
  }

  .button {
    margin: 0.5rem 0 2rem;
    height: 4rem;
    padding: 0 3rem;
    font-size: $smallSize;
  }
}

@media screen and (min-width: $tablet) {
  .login-form {
    padding: 3rem;
  }
}
