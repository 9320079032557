@mixin flexGap($gap, $direction: "row", $resetFirstLastMarginElement: "true") {
  > * {
    @if $direction == "row" {
      margin: 0 $gap/2;

      @if $resetFirstLastMarginElement == "true" {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @if $direction == "column" {
      margin: $gap/2 0;

      @if $resetFirstLastMarginElement == "true" {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
