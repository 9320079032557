.add-user-card {
  &:hover {
    transform: none;
    cursor: default;
  }
  .button {
    margin: auto;
  }
  .icon-user {
    // Remove number of sales by client waiting for v2 advanced stats feature, uncomment below to restore original layout with number of sales
    // margin-bottom: 3.2rem;
    margin-bottom: 2.1rem;
  }
}
