.select {
  -webkit-appearance: none;
  appearance: none;

  height: $inputHeight;
  width: 100%;
  font-size: $inputFontSize;
  padding: 0 1.5rem;
  margin-bottom: $inputMarginBottom;
  border: 1px solid $inputBorderColor;
  outline: none;
  background-color: $inputBackgroundColor;
  font-family: $roboto;
  color: $inputTextColor;

  &--error {
    border: 2px solid $inputError;
  }

  &--read-only {
    pointer-events: none;
  }

  &--grey {
    background-color: $inputBackgroundColor;

    &:hover,
    &:focus {
      background-color: $white;
    }
  }

  &:focus {
    border-color: $brandSecondary;
    border-width: 2px;
  }

  option:disabled {
    color: lightgray;
  }

  &--filter {
    border-radius: 0.6rem;
    font-size: $smallSize;
    font-weight: 100;
    color: $fontColor;
    text-transform: uppercase;
  }
}

.select-wrapper {
  position: relative;
  width: 100%;

  & > .select {
    padding-right: 2rem;
    text-overflow: ellipsis;
  }

  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-top: 0.6rem solid $inputArrow;
    right: 1.2rem;
    top: 1.5rem;
  }

  &--has-default-value {
    .select {
      color: $inputPlaceholder;
    }
  }
}
