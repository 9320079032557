.login-header {
  flex-basis: 44%;
  width: 100%;
  position: relative;
  min-height: 25rem;

  &__video-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
      100deg,
      rgba($brandPrimary, 0.75) 0%,
      rgba($lightBrandPrimary, 0.75) 100%
    );

    .logo {
      margin-bottom: 3rem;
    }

    .title {
      letter-spacing: 0.3ch;
    }

    .icon-wave {
      width: 4rem;
      margin-top: 1rem;
    }

    .title,
    .icon-wave {
      color: $white;
    }
  }
}

@media screen and (max-width: $tablet - 1) and (orientation: portrait) {
  .login-header {
    &__video-container {
      video {
        position: absolute;
        height: 100%;
        width: auto;
        right: auto;
        bottom: auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1) and (orientation: portrait),
  screen and (max-width: $desktop - 1) and (orientation: landscape) {
  .login-header {
    &__video-container {
      video {
        position: absolute;
        width: 100%;
        right: auto;
        bottom: auto;
        height: auto;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .login-header {
    flex-basis: 55%;
    &__gradient {
      .logo {
        width: 35rem;
      }
    }
  }
}
