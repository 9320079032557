.mandate-financial-informations-step {
  &,
  & + .step-actions {
    width: 100%;
    max-width: 36.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .mandate {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 0 1rem;
  }

  .financial-informations {
    display: flex;
    flex-direction: column;
    .financial-information {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > * {
        flex: 1;
      }

      &__label {
        height: $inputHeight;
      }
    }
  }

  .agency-fees-distribution {
    margin-bottom: 1.5rem;
    .fees-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      > * {
        width: 100%;
      }

      .repartition {
        margin-bottom: 1.5rem;
        font-size: $miniSize;
        &__block {
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 0 3rem;
          font-style: italic;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .mandate-financial-informations-step {
    .mandate {
      grid-template-columns: 1fr 1fr;
    }
    .agency-fees-distribution {
      .fees-wrapper {
        flex-direction: row;

        > * {
          flex: 1;
        }
      }
    }
  }
}
