.reset-password-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem 3rem;
  width: 100%;
  width: 100%;
  max-width: 55rem;

  .input {
    text-align: center;

    &::placeholder {
      font-style: italic;
    }
  }

  .button {
    margin: 2rem 0 2rem;
    padding: 0 3rem;
  }
}
