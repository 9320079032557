.sale-process {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
  &__card {
    margin-top: $boxSizeMargin;
    justify-content: center;
    position: relative;
    z-index: 2;
    .sale-card {
      width: 28rem;
      &__close-icon {
        opacity: 1;
      }
    }
  }
  &__validation-date {
    width: 100%;
    margin: 1rem 0;
    color: $white;
    font-size: 1.2rem;
    text-align: center;
  }

  &__steps-indicators {
    background-color: $white;
    width: 100%;
    padding-top: 1rem;
  }

  &__step-content {
    background-color: $shade01;
    flex-direction: initial;
    padding: $boxSizeMargin $boxSizeMargin * 0.5 $boxSizeMargin * 4;
  }
}

@media screen and (min-width: $tablet) {
  .sale-process {
    &__steps-indicators {
      width: 100%;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

@media screen and (min-width: $saleMenuBreakPoint) {
  .sale-process {
    &__card {
      margin: 3rem $boxSizeMargin * 0.5 0;
      max-width: 100%;
      width: auto;
      .sale-card {
        width: 30rem;
        padding-top: 2.5rem;
      }
    }
    &__step-content {
      padding: $boxSizeMargin $boxSizeMargin * 0.5 $boxSizeMargin * 3;
    }
  }
}
