.funding-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    @include flexGap(3rem, "column");
    padding-bottom: 4rem;
  }

  & > .dialog__container {
    max-width: 55rem;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    @include flexGap(1.5rem, "column");

    > .text {
      height: auto;
    }
  }
}

.formalities-and-documents-dialog {
  &__key-dates {
    @extend .key-dates-form__key-dates;

    @include flexGap(1rem, "column");

    .text {
      margin-bottom: 0.5rem;
    }

    textarea {
      margin-bottom: 0;
    }
  }

  &__checklist {
    padding: 0 2rem 2rem;
  }

  .dialog {
    &__container {
      max-width: 64rem;

      &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
      }
    }

    &__actions {
      padding: 0 6rem 2rem;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      @include flexGap(1rem, "column");

      .button {
        width: 100%;
      }
    }
  }

  .accordion {
    box-shadow: none;
    border-top: 1px solid $divider;
    border-bottom: 1px solid $divider;
    margin-bottom: 2rem;

    &-content {
      padding: 2rem;
    }

    &-header {
      background-color: transparent;

      &__title {
        color: $fontColor;
        font-weight: $semiBold;
        font-size: $smallSize;
      }

      &__toggle {
        color: $brandPrimary;
      }
    }
  }
}

@media screen and (min-width: $largeMobile) {
  .formalities-and-documents-dialog {
    .dialog {
      &__actions {
        flex-direction: row;
        @include flexGap(1rem, "row");
        padding: 0 4rem 2rem;

        .button {
          width: initial;
        }
      }
    }
  }
}

@media (min-width: $tablet) {
  .funding-dialog {
    &__content {
      flex-direction: row;
      @include flexGap(4rem, "row");
    }

    &__action {
      > .text {
        height: 3.2rem;
      }
    }
  }
}
