@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.attachment-uploader {
  position: relative;

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      display: none;
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $inputHeight;
    border-radius: 2em;
    background-color: transparent;

    cursor: pointer;
    font-size: $buttonFontSize;

    transition: 0.3s;
    letter-spacing: 0.1rem;

    &__label {
      flex: 1;
      text-align: left;
    }
  }

  &--disabled {
    pointer-events: none;

    .controller {
      border: 2px solid $disabledColor;
      color: $disabledColor;
    }
  }

  &-files {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;

    &__total-size {
      padding: 0.5rem 0;
    }

    .attachment-uploader-file {
      display: flex;
      align-items: center;
      font-weight: $bold;
      margin-right: 1rem;
      width: 100%;
      padding: 0.25rem 0;

      &__state {
        display: flex;
        margin-left: 1rem;
        .icon-spinner {
          color: $brandSecondary;
          animation: spin 1s infinite steps(16);
        }
        .icon-warning {
          color: $warning;
        }
      }

      &__action {
        .button {
          padding: 0;
          height: auto;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
