.user-crud-dialog {
  .dialog__title {
    font-size: 1.5rem;
  }
  .dialog__container {
    max-width: 47rem;
  }
  .dialog__actions {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
  form {
    padding-left: 2rem;
    padding-right: 2rem;
    .input,
    .select {
      font-size: $regularSize;
      letter-spacing: 0.05rem;
      height: 3.5rem;
      margin-bottom: 1rem;
    }
    .select-wrapper {
      flex: 0 0 11rem;
    }
  }
}
