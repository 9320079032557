.sale-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__percentage {
    font-size: 2.5rem;
    color: $fontColor;
  }

  .progress-bar {
    position: relative;
    overflow: hidden;
    width: 27.5rem;
    height: 2rem;
    margin: 0.5rem auto 0;
    border-radius: 1rem;
    background-color: rgba($white, 0.4);
    box-shadow: 0px 5px 10px 4px rgba(59, 60, 64, 0.15);

    &__gauge {
      height: 100%;
      border-radius: 1rem 0 0 1rem;
      background-color: $white;
      transition: 0.3s;
    }
  }
}
