.checkbox-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .label {
    user-select: none;
  }

  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    height: 1.8rem;
    min-height: 1.8rem;
    width: 1.8rem;
    min-width: 1.8rem;
    margin-right: 1rem;
    border: 1px solid #c3c3c3;
    transition-duration: 0.3s;
    cursor: pointer;
  }

  .checkbox:checked {
    border: 1px solid $brandSecondary;
  }

  .checkbox:checked::before {
    content: "";
    display: block;
    text-align: center;
    color: $white;
    position: absolute;
    left: 0.6rem;
    font-size: $inputFontSize;
    width: 0.4rem;
    height: 0.8rem;
    border: solid $brandPrimary;
    border-width: 0 3px 3px 0;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
  }

  .checkbox:active {
    border: 2px solid $brandSecondary;
  }
}
