$stepIndicatorWidth: 15rem;
$stepIndicatorWidthDesktop: 15rem;
$stepIndicatorBorderWidth: 1.1rem;

.step-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $stepIndicatorWidth;
  height: $stepIndicatorWidth;
  border-radius: 100%;
  background: $saleStepDisabledBgColor;
  color: $saleStepDisabledColor;
  transition: all 0.4s ease-out;
  margin: auto;
  cursor: not-allowed;
  &__inner {
    background-color: $white;
    width: $stepIndicatorWidth - $stepIndicatorBorderWidth * 2;
    height: $stepIndicatorWidth - $stepIndicatorBorderWidth * 2;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__number,
  &__title {
    text-transform: uppercase;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &__number,
  &__title,
  &__complete-status {
    font-family: $robotoSlab;
  }
  &__number {
    font-size: $miniSize;
    font-weight: $bold;
  }
  &__title {
    font-weight: normal;
    margin: 0;
    font-size: 1rem;
  }
  &__complete-status {
    font-size: $smallSize;
    font-weight: $bold;
    color: $brandPrimary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .icon-ripple {
    color: $saleStepDisabledColor;
    margin-top: 0.6rem;
    margin-bottom: 0.8rem;
  }

  // appearances
  &--ok,
  &--alert,
  &--upcoming {
    color: $saleStepColor;
    .icon-ripple {
      color: $brandPrimary;
    }
  }

  &--ok {
    background: linear-gradient(
      45deg,
      rgba($primaryGradientColor01, 1) 0%,
      rgba($primaryGradientColor02, 1) 100%
    );
  }

  &--upcoming {
    background: $saleStepUpcomingBgColor;
  }

  &--alert {
    background: $saleStepAlertBgColor;
  }

  &--active {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }

  &--is-accessible {
    cursor: pointer;
  }
}

@media screen and (min-width: $tablet) {
  .step-indicator {
    width: $stepIndicatorWidthDesktop * 0.9;
    height: $stepIndicatorWidthDesktop * 0.9;
    &__title {
      font-size: 0.9rem;
    }
    &__inner {
      width: ($stepIndicatorWidthDesktop * 0.9) -
        ($stepIndicatorBorderWidth * 0.9) * 2;
      height: ($stepIndicatorWidthDesktop * 0.9) -
        ($stepIndicatorBorderWidth * 0.9) * 2;
    }
    &__number {
      font-size: 1.3rem;
    }
    &__complete-status {
      font-size: $smallSize;
    }

    transition: all 0.3s ease-in-out;

    &--active {
      transform: scale(1.3);
      margin: 0 40px;
    }
    @keyframes scaleUp {
      to {
        -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -moz-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .step-indicator {
    width: $stepIndicatorWidthDesktop;
    height: $stepIndicatorWidthDesktop;
    &__title {
      font-size: 1rem;
    }
    &__inner {
      width: $stepIndicatorWidthDesktop - $stepIndicatorBorderWidth * 2;
      height: $stepIndicatorWidthDesktop - $stepIndicatorBorderWidth * 2;
    }
    &__number {
      font-size: 1.3rem;
    }
    &__complete-status {
      font-size: $smallSize;
    }
  }
}
