.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @include flexGap(3rem, "column");

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include flexGap(1.5rem, "column");
    text-align: center;

    > .text:first-child {
      font-size: $largeSize;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include flexGap(1.5rem, "column");
    width: 100%;
  }

  &__contact-us,
  &__contact-form {
    box-shadow: $cardShadow01;
  }

  &__contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include flexGap(1.5rem, "column");
    padding: 3rem;
    flex: 1;
    .icon-mobile {
      color: $white;
      background-color: $brandPrimary;
      width: 4.5rem;
      height: 4.5rem;
      padding: 1rem;
      border-radius: 50%;
    }
  }

  &__contact-form {
    flex: 2;
    padding: 3rem 3rem 1.5rem;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .select {
        margin-bottom: 0.5rem;
      }

      .button {
        width: 15rem;
      }
    }

    .rdw-editor-wrapper {
      width: 100%;
      margin-bottom: 1.5rem;

      .public-DraftEditorPlaceholder-inner {
        color: #757575;
      }
    }

    .rdw-editor-main {
      min-height: 10rem;
      background-color: $inputBackgroundColor;
      padding: 0 2rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .contact {
    &__header {
      > .text:first-child {
        font-size: $veryLargeSize;
      }
    }

    &__content {
      flex-direction: row;
      @include flexGap(1.5rem, "row");
      width: 80rem;
    }
  }
}
