.alert-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 2rem;
  overflow-y: auto;

  &__sections {
    width: 100%;
    margin-bottom: 4rem;
  }

  &__footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    button:last-child {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
}

.data-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__data-header {
    display: none;

    div {
      width: 13rem;
    }
  }

  &__title {
    color: $brandPrimary;
    font-size: 1.6rem;
    font-weight: $semiBold;
    padding-bottom: 0.75rem;
    padding-left: 0.5rem;
    border-bottom: 2px solid $shade04;
  }
}

.data-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    border-right: none;
    height: 4.5rem;
    padding-right: 2rem;
    margin-top: 1rem;

    span {
      color: $textColor02;
    }

    div:first-child {
      margin-right: 0.75rem;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    height: 4rem;
    margin-top: 0.5rem;
    font-weight: $semiBold;
    font-size: $smallSize;
    padding-left: 2rem;
    margin-left: 2.75rem;
    border-left: 2px solid $shade04;
    width: 100%;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 8rem;
    margin: 1rem 0 2rem 2rem;

    .input-number-wrapper {
      margin-left: 2rem;
      width: 10rem;
      padding-bottom: 0;

      .input {
        background-color: $shade01;
        padding: 0 0.25rem;
        text-align: center;
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    span {
      color: $brandPrimary;
      text-transform: uppercase;
      font-family: $robotoSlab;
      font-size: $smallSize;
      width: 12rem;
    }
  }

  &__no-cash-payment {
    margin-left: 4rem;
    width: 10rem;
  }
}

@media (min-width: $tablet) {
  .alert-settings {
    &__sections {
      width: 60rem;
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button:first-child {
        margin-right: 2rem;
      }

      button:last-child {
        margin-bottom: 0;
      }
    }
  }

  .data-item {
    &__inputs {
      .input-number-wrapper {
        margin-left: 4rem;
      }
    }

    &__input {
      span {
        width: 20rem;
      }
    }
  }
}

@media (min-width: $desktop) {
  .alert-settings {
    &__sections {
      display: flex;
      flex-direction: column;
      width: 95rem;
      margin-bottom: 3rem;
    }
  }

  .data-section {
    margin-bottom: 3rem;

    &__data-header {
      display: flex;
      justify-content: flex-end;
      color: $brandPrimary;
      text-transform: uppercase;
      font-family: $robotoSlab;
      font-size: $regularSize;
      text-align: center;
    }
  }

  .data-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;

    &__title {
      display: flex;
      align-items: center;
      border-right: 2px solid $shade04;
      font-style: italic;
      width: 40%;
    }

    &__label {
      height: 4.5rem;
      margin-top: 1rem;
      margin-left: 0;
      border-left: none;
      width: 26%;
    }

    &__inputs {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-left: 0;
      width: 35%;
      height: 4.5rem;
      margin-bottom: 0;
    }

    &__input {
      span {
        display: none;
      }
    }

    &__items {
      align-items: center;
    }

    &__no-cash-payment {
      width: 11rem;
    }
  }
}
