.labelled-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 100%;

  &__label {
    flex: 1;
    font-weight: $semiBold;
  }

  &__field {
    flex: 3;
    display: flex;
    @include flexGap(1rem, "row");

    > * {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: $tablet) {
  .labelled-field {
    align-items: center;
    flex-direction: row;
  }
}
