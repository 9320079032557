.radio-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .label {
    user-select: none;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    height: 2rem;
    width: 2rem;
    padding: 0;
    margin-right: 1rem;
    margin-bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
    transition-duration: 0.3s;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    border: 1px solid $brandSecondary;

    &::before {
      background: $brandSecondary;
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      width: 1.2rem;
      border-radius: 50%;
      pointer-events: none;
    }
  }

  input[type="radio"]:active {
    border: 1px solid #c3c3c3;
  }
}
