@mixin keyframes($overlay-background-animation) {
  @keyframes #{$overlay-background-animation} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

@include keyframes(overlay-background-animation) {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.ReactModal {
  &__Overlay {
    z-index: $zIndexDialogOverlay;
    background-color: transparent !important;
  }

  &__Content {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    inset: 0 !important;
    width: 100%;

    @supports not (inset: 40px) {
      margin-top: -40px;
      margin-left: -40px;
    }
  }
}
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: $zIndexDialogOverlay;
    @include animation("overlay-background-animation 250ms linear 1");
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 3rem);
    background-color: $white;
    overflow: auto;
    z-index: $zIndexDialogContainer;
    margin: 1.5rem;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 1.5rem 0;

    .button {
      height: auto;
      width: auto;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include flexGap(0.5rem, "column");
    padding: 0 2rem 2rem;
    font-size: $mediumSize;
    color: $brandPrimary;

    > *:not(:first-child) {
      margin-top: 0.5rem;
    }

    .icon-ripple {
      width: 4rem;
    }
  }

  &__content {
    padding: 0 2rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    @include flexGap(1.5rem, "row");
    justify-content: center;
    padding: 2rem 2rem;
    width: 100%;
  }
}

.dialog-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5rem 3rem 5rem;

  &__head {
    &__title {
      color: $inputPlaceholder;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      font-family: $robotoSlab;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    button:first-child {
      margin-right: 1.5rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .dialog {
    &__actions {
      flex-direction: row;
    }

    &__title {
      padding: 0 4rem 2rem;
    }

    &__content {
      padding: 0 4rem;
    }

    &__container {
      height: 100%;
    }

    &__actions {
      padding: 2rem 4rem;
    }
  }
}

@media screen and (min-width: $desktop) {
  .dialog {
    &__container {
      height: auto;
      max-height: calc(100% - 6rem);
      max-width: 90rem;
    }
  }
}
