.sale-process-compromise-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include flexGap(2rem, "column");

  .sale-process-card {
    &__fields--inline {
      display: flex;
      @include flexGap(1rem, "row");
      justify-content: center;
    }
  }
}

.key-dates-form {
  margin: 2.5rem 0 1.5rem;

  &__key-dates {
    display: flex;
    flex-direction: column;

    .key-date-input {
      display: flex;
      flex-direction: column;
      @include flexGap(0.5rem, "column");

      .text {
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        background-color: $inputBackgroundColor;
        border: 1px solid $inputBorderColor;
      }

      .text,
      .input {
        margin-bottom: 0.5rem;
      }
    }
  }

  .input--textarea {
    margin-bottom: 0.5rem;
  }

  .radio-group {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }
}

.send-confirmation-email-dialog {
  .dialog__container {
    max-width: 60rem;
  }
}

.sign-planning-dialog {
  .dialog__container {
    max-width: 50rem;
  }
  .dialog__actions {
    padding-top: 0.5rem;
    justify-content: center;
    @include flexGap(5rem, "row");
  }
  &__action {
    .icon {
      margin-bottom: 1rem;
    }
  }
}

.key-dates-dialog {
  .dialog {
    &__container {
      max-width: 55rem;
    }
    &__actions {
      justify-content: center;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      .button {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .sale-process-card {
    width: 38rem;
  }

  .key-dates-form {
    &__key-dates {
      .key-date-input {
        flex-direction: row;
        @include flexGap(0.5rem, "row");

        .text {
          flex: 2;
        }
        .input-date {
          flex: 1;
        }
      }
    }

    .radio-group {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: $desktop) {
  .sale-process-compromise-step {
    flex-direction: row;
    @include flexGap(2rem, "row", "false");
    align-items: inherit;

    .sale-process-card:last-child {
      margin-top: 2rem;
    }
  }
}

@media screen and (min-width: 1224px) {
  .sale-process-compromise-step {
    .sale-process-card:last-child {
      margin-top: 0;
    }
  }
}
