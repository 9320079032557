* {
  box-sizing: border-box;
}

html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  background: $backgroundColor;
  scroll-behavior: smooth;
}

body.overflow-hidden {
  overflow: hidden;
}

#root {
  height: 100%;
}

/* 
  Prevent user selection on mobile (text, button, ...) 
  source : https://stackoverflow.com/a/4407335 
  issue : https://stackoverflow.com/a/4407335
*/
@media screen and (max-width: $tablet - 1px) {
  *:not(select, input, textarea, .rdw-editor-wrapper, .rdw-editor-wrapper *) {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
}
