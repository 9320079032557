.user-settings {
  max-width: 100%;
  &__header-content {
    text-transform: uppercase;
    font-weight: $light;
    width: 25rem;
    text-align: center;
    margin: 0;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    text-align: center;
  }

  &__users-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    .user-card {
      width: 100%;
      max-width: 31rem;
      margin: auto;
    }
  }

  &__accounts-left {
    font-family: $robotoSlab;
    padding-top: 0;
    padding-bottom: 3rem;
    font-size: $regularSize;
    text-align: center;
  }
}

@media (min-width: $tablet) {
  .user-settings {
    &__users-list {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      max-width: 68rem;
      .user-card {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: $desktop) {
  .user-settings {
    &__users-list {
      grid-template-columns: repeat(3, 1fr);
      max-width: 110rem;
    }
  }
}
