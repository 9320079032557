.formula {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 35rem);
  padding-bottom: 6rem;

  &__your-formula {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: fit-content;
    @include flexGap(1rem, "column");
    padding: 2.5rem 4rem;
    box-shadow: $cardShadow01;
    margin-bottom: 4rem;

    .icon-ripple {
      margin-bottom: 1rem;
    }
  }

  &__informations {
    display: flex;
    flex-direction: column;
    @include flexGap(1.5rem, "column");
    margin-top: 4rem;
  }

  &__contact-us {
    text-decoration: underline;
    color: $brandPrimary;
    font-weight: $bold;
    cursor: pointer;
  }

  &__cgv-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1rem;
    text-align: center;

    a {
      font-style: normal;
      font-weight: $regular;
      color: $textColor04;
    }
  }

  &__business-offer {
    a {
      text-decoration: underline;
      color: $brandPrimary;
      margin-left: 0.5rem;
    }
  }
}

@media screen and (min-width: $tablet) {
  .formula {
    min-height: calc(100vh - 35rem);

    &__your-formula {
      padding: 2.5rem 5rem;
    }
  }
}
