.email-settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__sections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__title {
      color: $brandPrimary;
      font-size: 1.6rem;
      font-weight: $semiBold;
      padding-bottom: 0.75rem;
      padding-left: 0.5rem;
      border-bottom: 2px solid $shade04;
      width: 100%;
    }

    &__items {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem 1rem 2rem 1rem;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background-color: $shade01;
      padding: 0.5rem 1.25rem;
      margin-bottom: 0.75rem;
      font-style: italic;
      transition: background-color 300ms;

      &:hover {
        background-color: $shade04;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item.active {
      background-color: $brandPrimary;
    }
  }

  &__edition {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: $cardShadow01;
    border-radius: 0.5rem;
    width: 100%;
    min-height: 32rem;
    padding: 2.5rem;

    .header {
      width: 100%;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid $shade04;

      span {
        color: $brandPrimary;
      }

      &__title {
        padding-top: 0.5rem;
        font-weight: $bold;
        font-size: $mediumSize;
      }
    }

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5rem 0;

      button {
        width: 100%;

        &:first-child {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

@media (min-width: $tablet) {
  .email-settings {
    width: 100%;

    &__sections {
      margin-right: 0;
    }
  }
}

@media (min-width: $desktop) {
  .email-settings {
    width: 90rem;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &__sections {
      margin-right: 2rem;
    }

    &__edition {
      .footer {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        button {
          width: auto;

          &:first-child {
            margin-right: 2rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (min-width: $largeDesktop) {
  .email-settings {
    width: 110rem;

    &__sections {
      margin-right: 4rem;
    }
  }
}
