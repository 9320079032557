.sale-layout {
  min-height: 100vh;

  &--edit {
    .generic-layout-header {
      height: 34rem;
    }
  }

  .generic-layout-header {
    .header-content {
      width: 100%;
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .sale-card-wrapper {
    margin-bottom: 1rem;
    width: 100%;
  }

  &__back-button {
    position: absolute;
    bottom: 1rem;
    left: 1rem;

    .button {
      background-color: transparent;
      border: 1px solid $white;
      color: $white;
      box-shadow: 0 0 0 rgb(255 255 255 / 0%);
      height: 2rem;
      padding: 0 1rem;

      &-text {
        display: none;
      }

      .icon {
        width: 1rem;
        min-width: 1rem;
      }

      &:hover,
      &:focus {
        background-color: $white;
        color: $brandPrimary;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .sale-layout {
    .generic-layout-header {
      height: 26rem;
    }

    .sale-card-wrapper {
      margin: 2rem 0;
      width: 32.5rem;
    }

    &__back-button {
      position: initial;

      .button {
        height: 3.5rem;
        padding: 0 1.5rem;

        &-text {
          display: initial;
        }
      }
    }
  }
}
