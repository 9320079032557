@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin keyframes($text-opacity) {
  @keyframes #{$text-opacity} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

@include keyframes(text-opacity) {
  0% {
    color: rgba(255, 255, 255, 0);
  }

  60% {
    color: rgba(255, 255, 255, 0);
  }

  100% {
    color: rgba(255, 255, 255, 1);
  }
}

// Buttons
$buttonHeight: 3.5rem;
$buttonFontSize: 1.3rem;

.button {
  display: inline-block;
  height: $buttonHeight;
  cursor: pointer;
  font-size: $buttonFontSize;
  font-family: $roboto;
  border-radius: 2em;
  border: none;
  padding: 0 1.5rem;
  transition: $defaultDuration;
  letter-spacing: 0.1ch;

  &:hover,
  &:focus {
    outline: none;
  }
}

.button--link {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & .link {
    font-style: normal;

    &:focus {
      text-decoration: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    .link {
      color: inherit;
      text-decoration: none;
    }
  }
}

.button--default {
  background-color: $white;
  color: $fontColor;
}

.button--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  color: inherit;
  background: transparent;

  .button-text {
    flex: 1;
    &--left {
      margin-right: 0.75rem;
    }

    &--right {
      margin-left: 0.75rem;
    }

    &--bottom {
      margin-top: 1.25rem;
    }
  }

  &:hover,
  &:focus {
    background: transparent;
  }
}

%primary-hover {
  background-color: $brandPrimaryHover;
  border-color: $brandPrimaryHover;
}

.button--primary {
  background-color: $brandPrimary;
  color: $white;
  border-color: $brandPrimary;
  box-shadow: 0 5px 10px rgb(59 60 64 / 20%);

  &:hover,
  &:focus {
    @extend %primary-hover;
  }
}

%reversed-primary-hover {
  color: $white;
  background-color: $brandPrimary;
}

.button--reversed-primary {
  background-color: $white;
  color: $brandPrimary;
  border: 1px solid $brandPrimary;

  .link {
    color: $brandPrimary;
    text-transform: initial;
  }

  &:hover,
  &:focus {
    @extend %reversed-primary-hover;
  }
}

.button--secondary {
  background-color: $brandSecondary;
  color: $white;
  border-color: $brandSecondary;

  &:hover,
  &:focus {
    background-color: darken($brandSecondary, 10%);
    border-color: darken($brandSecondary, 10%);
  }
}

.button--ghost {
  background-color: $white;
  color: $fontColor;

  &:hover,
  &:focus {
    background-color: darken($white, 3%);
  }
}

.button--ghost-primary {
  background-color: $white;
  color: $brandPrimary;
  box-shadow: 0 5px 10px rgb(59 60 64 / 20%);

  &:hover,
  &:focus {
    background-color: $brandPrimary;
    color: $white;
  }
}

.button--reversed-tertiary {
  background-color: $white;
  color: $brandTertiary;
  border: 1px solid $brandTertiary;

  &:hover,
  &:focus {
    color: $white;
    background-color: $brandTertiary;
  }
}

.button--transparent {
  background-color: transparent;
}

.button--grey {
  color: $disabledColor;
  box-shadow: none;
  background-color: $white;
  border: 1px solid $disabledColor;
}

.button--full-width {
  width: 100%;
}

.button--no-padding {
  padding: 0;
}

.button--rounded {
  border-radius: 100%;
  padding: 2.1rem;
}

.button--rounded-to-full-width-animation {
  border-radius: 4rem;
  padding: 0.5rem;
  height: 4rem;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
  .button-text {
    font-size: $regularSize;
    white-space: nowrap;
    letter-spacing: 0.2ch;
  }
}

.button--loading {
  @extend .button--disabled;

  .icon {
    animation: spin 1s infinite steps(64);
  }
}

@media screen and (min-width: $tablet) {
  .button--rounded-to-full-width-animation {
    overflow: hidden;
    transition: all 200ms ease-in-out;
    max-width: 4rem;
    .button-text {
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      max-width: 100%;
      .button-text {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.button--disabled {
  color: $disabledColor;
  box-shadow: none;
  background-color: $white;
  border: 1px solid $disabledColor;

  &:hover,
  &:focus {
    background-color: $white;
    color: $disabledColor;
    border: 1px solid $disabledColor;
  }
}

.button--vertical {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.button--shadow {
  box-shadow: 0 5px 10px rgb(59 60 64 / 20%);
}

.button--uppercase {
  text-transform: uppercase;
}

.button--italic {
  font-style: italic;
}

.button--icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $buttonHeight;
}

.button--size {
  &-auto {
    height: auto;
    width: auto;
  }
}

.button--text-size {
  &-mini {
    font-size: $miniSize;
  }
}

.button--naked,
.button--link-primary {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  height: auto;
  letter-spacing: 0;
}

.button--link-primary {
  font-size: $regularSize;
  color: $brandPrimary;
  text-transform: lowercase;

  &:hover {
    text-decoration: underline;
  }
}
