.file-uploader {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;

  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      display: none;
    }
  }

  &--disabled {
    input[type="file"] {
      cursor: initial;
    }
  }

  .controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $inputHeight;
  }
}
