.planning-calendar {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem 1rem;
    background-color: transparent;
  }

  &__date {
    text-transform: capitalize;
    font-weight: $bold;
    font-size: $extraSmallSize;
  }

  &__controls {
    display: flex;
    @include flexGap(2rem, "row");

    .button {
      width: auto;
    }
  }

  .react-datepicker {
    width: 100%;
    border: none;
    padding: 1rem;
    background-color: $shade01;
    font-size: 1rem;
    font-family: $roboto;

    &__header {
      background-color: $shade01;
      border-bottom: none;
    }

    &__month-container {
      width: 100%;
    }

    &__month {
      margin: 0;
      padding: 0 0.4rem 0.4rem;
      background-color: $shade01;
    }
  }

  .react-datepicker__day {
    &--keyboard-selected {
      border: none;
      background-color: transparent;
      color: inherit;
    }

    &--today {
      border-radius: 50%;
      background-color: transparent;
      color: inherit;
      border: 1px solid $shade03;
      font-weight: $regular;
    }

    &--outside-month {
      color: $disabledColor;
    }

    &--selected {
      background-color: transparent;
      color: inherit;
    }

    &--has-entry {
      background-color: #ccc;
    }

    &--has-common-entry {
      background-color: $brandPrimary;
      color: $white;
      border: none;
    }
  }

  .react-datepicker__day.react-datepicker__day--has-entry.react-datepicker__day--outside-month {
    color: $white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    line-height: initial;
  }
}

@media screen and (min-width: $tablet) {
  .planning-calendar {
    &__header {
      padding: 0 1rem 1rem;
    }

    .react-datepicker {
      width: auto;

      &__month-container {
        width: auto;
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      height: 2rem;
      width: 2rem;
    }
  }
}
