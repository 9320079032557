$saleMenuBreakPoint: $desktop;

.sale-process-menu {
  display: flex;
  width: 100%;
  background-color: $brandPrimary;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-top: 1rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  justify-content: center;
  align-self: flex-end;

  .button {
    color: $white;

    svg {
      width: auto;
    }
  }

  .container {
    width: auto;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    .button {
      .icon--size-big {
        width: auto;
        height: 4rem;
      }
    }
  }
}

@media screen and (min-width: $saleMenuBreakPoint) {
  .sale-process-menu {
    position: absolute;
    padding-top: $boxSizeMargin;
    padding-bottom: $boxSizeMargin;
    background-color: transparent;

    .ripple-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.5rem;
    }

    .sale-menu-item {
      min-height: 6.5rem;
    }

    .icon-ripple {
      display: inline-block;
      width: 3rem;
      color: $white;
    }

    .button {
      height: 100%;
      &:hover {
        color: darken($brandPrimary, 15%);
      }
    }

    .button {
      text-transform: uppercase;
      font-size: 1rem;
    }

    ul {
      .button {
        .icon--size-big {
          height: 3.5rem;
        }
      }
    }
  }

  .sale-menu-item {
    &--gap-left {
      margin-left: 40rem;
    }
  }
}

@media screen and (max-width: $saleMenuBreakPoint - 1) {
  .sale-process-menu {
    z-index: $zIndexSaleProcessMenu;

    .icon-ripple {
      display: none;
    }

    .button {
      .icon--size-big {
        width: auto;
        height: 4rem;
      }

      &-text {
        font-size: 1rem;
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }
}
