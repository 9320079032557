.Toastify {
  .animate__animated {
    &.animate__slideInUp,
    &.animate__fadeOut {
      --animate-duration: 0.1s;
    }
  }

  &__toast {
    display: flex;
    align-items: center;
    min-height: 4.6rem;
    padding-right: 1.6rem;
    font-family: $roboto;
    font-weight: 300;

    &-container {
      width: auto;
      min-width: 32rem;
      max-width: 96rem;
      right: 0;
    }

    &-body {
      white-space: break-spaces;
    }

    &--error {
      background-color: $notifyError;
    }

    &--success {
      background-color: $notifySuccess;
    }

    &--warning {
      background-color: $warning;
    }

    .button--icon-only {
      width: 3rem;
      height: 3rem;
      margin-left: 0.8rem;
      padding: 0;
      border: 1px solid $white;
      border-radius: 50%;
      background-color: transparent;
      color: inherit;

      .icon-close {
        width: 1.2rem;
        min-width: 1.2rem;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .Toastify {
    &__toast-container {
      right: 1rem;
    }
  }
}
