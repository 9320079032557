.user-card {
  transition: 0.3s all ease-in-out;
  position: relative;
  &__content {
    width: 100%;
  }
  &__close-icon {
    position: absolute;
    top: 1rem;
    right: 0;
    transition: all 250ms ease-in-out;
    button {
      height: 2rem;
    }
  }

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  &__sales-count {
    font-size: $smallSize;
    &--empty {
      font-size: $regularSize;
      font-style: italic;
    }
  }

  &__people {
    font-weight: $bold;
    font-size: 2rem;
    color: $fontColor;
    letter-spacing: 0.05em;
    .people-lastname {
      text-transform: uppercase;
    }
  }
}


@media (min-width: $largeDesktop) {
  .user-card {
    width: 35rem;
  }
}