.input-number-wrapper {
  display: flex;
  padding-bottom: 1.5rem;

  .input {
    margin-bottom: 0;
    border-right: none;
  }

  .suffix-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include flexGap(0.6rem, "row");
    padding: 0 1.1rem;
    height: $inputHeight;
    background-color: $shade04;
    border: 1px solid $inputBorderColor;
  }

  .input--error + .suffix-wrapper {
    border: 2px solid $inputError;
  }
}
