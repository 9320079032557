.statistics-header {
  &__key-numbers {
    font-size: 3rem;
    color: $textColor01;
    text-transform: uppercase;
  }
}

.statistics {
  width: 100%;
  max-width: 100%;

  --swiper-theme-color: #{"" + $swiperPaginationColor};
  .swiper-container {
    .swiper-pagination {
      &-bullet {
        width: 6px;
        height: 6px;
        margin: 0 2px;
      }

      &-bullet:not(.swiper-pagination-bullet-active) {
        background: $fontColor;
        opacity: 0.8;
      }
    }
  }

  &-recap {
    background-color: $shade01;
    padding-bottom: 1rem;

    .swiper-container {
      height: 25rem;
    }

    &.--stat-cards-only {
      width: 100%;
    }

    &__icon {
      display: flex;
      justify-content: center;
      padding: 3rem 0;
    }

    &__cards {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      margin: 0;
      padding-bottom: 0;
    }

    &__swipper {
      display: block;
      margin: 0 1rem;
    }
  }

  .input-date {
    input {
      background-color: $white;
    }
  }
}

@media (min-width: $tablet) {
  .statistics-recap {
    padding-bottom: 3rem;

    &__swipper {
      display: none;

      &__cards {
        margin: 0 2rem 2rem 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}

@media (min-width: $desktop) {
  .statistics-recap {
    &__cards {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }
}
