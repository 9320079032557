.icon {
  display: inline-block;

  &--size {
    &-mini {
      min-width: 0.8rem;
      width: 0.8rem;
    }

    &-extra-small {
      min-width: 1rem;
      width: 1rem;
    }

    &-small {
      min-width: 1.2rem;
      width: 1.2rem;
    }

    &-default {
      min-width: 1.6rem;
      width: 1.6rem;
    }
    &-medium {
      min-width: 2rem;
      width: 2rem;
    }
    &-big {
      min-width: 3rem;
      width: 3rem;
    }
    &-extra-big {
      min-width: 4rem;
      width: 4rem;
    }
    &-very-big {
      min-width: 5rem;
      width: 5rem;
    }
    &-bigger {
      width: 6rem;
    }
  }

  &--color {
    &-primary {
      color: $brandPrimary;
    }
    &-secondary {
      color: $brandSecondary;
    }
    &-tertiary {
      color: $brandTertiary;
    }
    &-grey {
      color: $disabledColor;
    }
    &-medium-grey {
      color: $shade05;
    }
    &-dark-grey {
      color: $shade03;
    }
    &-black {
      color: $black;
    }
    &-white {
      color: $white;
    }
  }

  svg {
    display: block;
    fill: currentColor;
    width: 100%;
    height: 100%;
  }
}
