.menu-button-container {
  color: $brandPrimary;

  .icon-menu {
    width: 1.6rem;
  }

  .text {
    display: none;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: $zIndexMenu;
  width: 100%;
  height: 100%;
  border-right: 1px solid #e2e2d6;
  transition: transform 0.3s ease;
  transform: translateX(calc(-100%));
  overflow-y: auto;

  &--opened {
    transform: translateX(0);
    box-shadow: 3px 3px 18px rgba(59, 60, 64, 0.2);
  }

  &__close {
    width: 100%;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;

    .icon-close {
      width: 1.2rem;
      color: $shade03;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      color: $brandPrimary;

      &-logo {
        width: 10.8rem;
        margin-bottom: 2.2rem;
      }

      &-wave {
        color: $brandPrimary;
        width: 4rem;
        margin-bottom: 4.5rem;
      }
    }
  }

  &__content {
    background-color: $white;
    height: 100%;
    max-width: 100%;
    padding: 0rem 1.5rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
  }
}

.menu-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ripple {
    display: flex;
    justify-content: center;
    margin: 0.4rem 0 1.4rem;

    .icon-ripple {
      width: 4.4rem;
      color: $brandPrimary;
    }
  }
}

.menu-navigation-item {
  border: 1px solid $shade02;
  border-radius: 2.2rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  transition: 0.3s;

  .left-block {
    display: flex;
    align-items: center;
    font-weight: $semiBold;
    .icon {
      margin-right: 0.75rem;
    }
  }

  .right-block {
    .notification-circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 1.9rem;
      width: 1.9rem;
      color: $white;
      background-color: $brandTertiary;
      border-radius: 50%;
    }
  }

  .left-block,
  .right-block {
    .icon {
      transition: 0.3s;
      color: $brandPrimary;
      width: 1.6rem;
    }

    .icon-lock-close {
      width: 1.2rem;
    }
  }

  &--disabled {
    .left-block,
    .right-block {
      color: $disabledColor;
      .icon {
        color: $disabledColor;
      }
    }

    .menu-navigation-item__link {
      cursor: not-allowed;
    }
  }

  &--active:not(&--disabled) {
    background-color: $brandPrimary;
    border-color: $brandPrimary;
    color: $white;
    font-weight: $regular;

    .icon {
      color: $white;
    }

    .left-block {
      .icon {
        margin-right: 1.2rem;
      }
    }
  }

  &:not(&--disabled):not(&--active):hover,
  &:not(&--disabled):not(&--active):focus {
    background-color: $brandPrimaryHover;
    border-color: $brandPrimaryHover;
    box-shadow: 0 0.5rem 1rem rgba(59, 60, 64, 0.2);
    color: $white;

    .icon {
      color: $white;
    }

    .left-block {
      .icon {
        margin-right: 1.2rem;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s;
    padding: 1rem 1.2rem;
    letter-spacing: 0.05rem;
    height: 3.5rem;
    line-height: 1;
  }
}

@media screen and (min-width: $tablet) {
  .menu-button-container {
    color: $white;

    .text {
      display: initial;
    }
  }

  .menu {
    width: 38rem;
    transform: translateX(-38rem);

    &--opened {
      transform: translateX(0);
    }

    &__content {
      padding: 0rem 3rem;
    }
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    z-index: $zIndexMenuOverlay;

    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    &--opened {
      visibility: visible;
      opacity: 0.8;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
}
