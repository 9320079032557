// brand colors
$brandPrimary: #2addc0;
$lightBrandPrimary: #47ecec;
$brandSecondary: #69d9c1;
$brandTertiary: #ff837a;
$lightBrandTertiary: #ffa19c;

// hover colors :

$brandPrimaryHover: #1fccaf;

// text colors
$textColor00: #1a1a1a;
$textColor01: #474c47;
$textColor02: #666666;
$textColor03: #898c86;
$textColor04: #898c89;
$textColorPrimary: #1ed6b8;

// gradient colors
$primaryGradientColor01: #07dfab;
$primaryGradientColor02: $lightBrandPrimary;
$primaryBackgroundGradient: linear-gradient(
  45deg,
  $primaryGradientColor01 0%,
  $primaryGradientColor02 100%
);

$headerBackgroundGradient: linear-gradient(
  45deg,
  $primaryGradientColor02 0%,
  $primaryGradientColor01 100%
);
$headerBackgroundImage: url("../../media/images/back-header.png");

// generic colors
$white: #fff;
$black: #000;

$notifyError: #ff5a4d;
$notifySuccess: #1fccaf;

$success: $brandSecondary;
$warning: #fbb03b;
$error: #f44336;

$divider: #c7c7c7;

$shade01: #f7f7f4;
$shade02: #dbdbd7;
$shade03: #b2b2b2;
$shade04: #f0f0ee;
$shade05: #b3b3b3;

// body colors
$backgroundColor: $white;
$fontColor: $textColor01;
$fontColorAlt: $textColor01;
$linkColor: $textColor02;
$disabledColor: #d4d4d4;

// input colors
$inputError: #ff7d6e;
$inputPlaceholder: #757575;
$inputBackgroundColor: #f7f7f7;
$inputBorderColor: $inputBackgroundColor;
$inputTextColor: $black;
$inputArrow: #727270;

// card colors
$cardShadow01: 0px 5px 10px 4px rgba(59, 60, 64, 0.15);
$cardShadow02: 0px 5px 10px 4px rgba(59, 60, 64, 0.2);
$cardShadow03: 0px 4px 8px 4px rgb(59, 60, 64, 0.15);
$fontGreyCard: #8a8c89;

// stepper progress bar
$stepperBackground: #f2f2ef;

// swiper slider colors
$swiperPaginationColor: $brandPrimary;

// sale step colors
$saleStepColor: $fontColor;
$saleStepDisabledColor: $shade03;
$saleStepDisabledBgColor: $shade01;
$saleStepAlertBgColor: $brandTertiary;
$saleStepUpcomingBgColor: #f0f0ed;

// login form colors
$loginFormLinkColor: #b9b9b9;
