.statistics-table {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 3rem 0;

  .swiper-container {
    padding-bottom: 4rem;
  }

  &__over-head {
    td:not(:first-child) {
      border-bottom: 2px solid $shade04;
      padding: 1rem 0;
      color: $textColorPrimary;
      font-family: $robotoSlab;
      font-weight: $regular;
    }
  }

  &__head {
    text-align: center;
    font-weight: $bold;
    font-size: 1.2rem;

    td {
      padding: 0.5rem 0;

      &:first-child {
        text-align: start;

        span {
          &:first-child {
            margin: 0 2rem 0 0.5rem;
          }
        }
      }
    }
  }

  table {
    width: 110rem;
    font-size: $smallSize;
    text-align: center;
    font-weight: $semiBold;

    tbody {
      td {
        padding: 0.5rem 0;

        &:first-child {
          text-align: start;

          span {
            &:first-child {
              margin: 0 3rem 0 1rem;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 9rem;
        }

        &:nth-child(7),
        &:nth-child(8) {
          width: 13rem;
        }
      }

      td:not(tr:first-child > td) {
        &:nth-child(odd) {
          background-color: $shade01;
        }

        &:nth-child(even) {
          background-color: $inputBorderColor;
        }
      }

      tr {
        &:first-child {
          td {
            color: $white;

            &:nth-child(odd) {
              background-color: $brandPrimary;
            }

            &:nth-child(even) {
              background-color: lighten($color: $brandPrimary, $amount: 12%);
            }
          }
        }

        td {
          &:nth-child(7),
          &:nth-child(8) {
            text-align: end;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  &__body {
    &__status {
      align-items: center;
    }
  }
}

.mobile-table-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: $semiBold;

  &__title {
    padding: 0 6rem;
    width: 100%;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 6rem;
    padding: 0.5rem 0;

    &:first-child {
      justify-content: flex-start;
      flex: 1.75;
      padding-right: 1rem;

      span {
        &:first-child {
          margin: 0 1rem;
        }
      }
    }

    &:nth-child(odd) {
      background-color: $shade04;
    }

    &:nth-child(even) {
      background-color: $shade01;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid $white;

    .mobile-table-data__column {
      &:first-child {
        border-right: 2px solid $white;
      }
    }

    &:first-child {
      .mobile-table-data__column {
        color: $white;

        &:nth-child(odd) {
          background-color: $brandSecondary;
        }

        &:nth-child(even) {
          background-color: $brandPrimary;
        }
      }
    }
  }

  &__head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: $semiBold;
    font-size: $smallSize;

    .mobile-table-data__column {
      background-color: $white;

      &:first-child {
        justify-content: center;
      }
    }
  }
}
