@import "./Fonts/fonts";

$roboto: "Roboto", sans-serif;
$robotoSlab: "RobotoSlab", serif;

$light: 300;
$regular: 400;
$semiBold: 500;
$bold: 700;

$extraSmallSize: 1rem;
$miniSize: 1.2rem;
$smallSize: 1.4rem;
$regularSize: 1.6rem;
$mediumSize: 1.8rem;
$largeSize: 2rem;
$veryLargeSize: 2.6rem;
$extraLargeSize: 2.8rem;

$defaultLineHeight: 1.15;
$mediumLineHeight: 1.3;
$largeLineHeight: 1.5;

html {
  /** We use 62.5% as default font-size so that 1rem corresponds to 10 pixels */
  font: normal 62.5% $roboto, Arial, Verdana, Helvetica, sans-serif;
}

body {
  color: $fontColor;
  font-family: $roboto;
  font-style: normal;
  font-weight: $regular;
  font-size: $regularSize;
  line-height: $defaultLineHeight;
}
