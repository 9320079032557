.characteristics-step {
  margin-bottom: 1.5rem;

  &,
  & + .step-actions {
    width: 100%;
    max-width: 36.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .annexes {
    display: flex;
    flex-wrap: wrap;
    @include flexGap(1rem, "column", "false");
    margin-bottom: 1.5rem;
    > * {
      flex-basis: calc(33% - 2rem / 3);
    }
  }
}

@media screen and (min-width: $tablet) {
  .characteristics-step {
    .property-type {
      display: flex;
      @include flexGap(1.5rem, "row", "false");

      .select-wrapper[data-parent-of="propertyType"] {
        flex: 2;
      }

      .input[name="numberOfRooms"],
      .input[name="area"] {
        flex: 1;
      }
    }

    .co-ownership {
      display: flex;
      flex-direction: column;
      .select-wrapper[data-parent-of="coOwnership"] {
        flex: 1;
      }

      .select-wrapper[data-parent-of="numberOfLots"],
      .select-wrapper[data-parent-of="year"] {
        flex: 2;
      }
    }
  }
}
