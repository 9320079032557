.sale-multistep {
  width: 100%;
  max-width: 70rem;

  &__step {
    &--inactive {
      .accordion {
        .accordion-header {
          background-color: $white;
          &__title {
            color: $black;
          }

          &__toggle {
            color: $brandSecondary;
          }
        }
      }
    }

    input,
    select,
    textarea {
      background-color: $inputBackgroundColor;
    }

    .divider {
      margin: 0.5rem 0 2rem;
    }

    .text {
      margin-bottom: 1.5rem;
    }
  }

  .step-actions {
    display: flex;
    flex-direction: column;
    @include flexGap(1rem, "column");
  }
}

@media screen and (min-width: $tablet) {
  .sale-multistep {
    .choose-sale-type {
      .sale-types-wrapper {
        flex-direction: row;
        @include flexGap(4rem, "row");
      }
    }

    .accordion .accordion-content {
      padding: 1.6rem 10rem 4rem;
    }

    .step-actions {
      flex-direction: row;
      @include flexGap(1rem, "row");

      > .button:first-child {
        flex: 1;
      }
    }
  }
}

@media screen and (min-width: $desktop) {
  .sale-multistep {
    .accordion .accordion-content {
      padding: 1.6rem 5rem 4rem;
    }
  }
}

@import "./steps/ChooseSaleType/chooseSaleType";
@import "./steps/CharacteristicsOfThePropertyStep/characteristicsOfThePropertyStep";
@import "./steps/PartnerAgencyStep/partnerAgencyStep";
@import "./steps/MandateAndFinancialInformationsStep/mandateAndFinancialInformationsStep";
@import "./steps/ClientsAndNotariesStep/clientsAndNotariesStep";
@import "./steps/DocumentsStep/documentStep";
@import "./steps/AgentInChargeStep/agentInChargeStep";
