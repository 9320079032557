.sale-card-wrapper {
  position: relative;
  width: 100%;

  &__close-icon {
    position: absolute;
    right: 0.75rem;
    top: 2.5rem;
    padding: 0;
    height: auto;
    opacity: 0;
    z-index: 1;
    transition: all $defaultDuration;

    .button {
      height: 0;
      color: $shade03;

      &:hover {
        color: $brandPrimary;
      }
    }

    &:hover {
      + .sale-card {
        background-color: $shade01;

        .button {
          background-color: $shade01;
        }
      }
    }
  }

  &:hover {
    .sale-card-wrapper__close-icon {
      opacity: 1;
    }
  }
}

.sale-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 22rem;
  box-shadow: $cardShadow01;
  background-color: $white;
  transition: transform $defaultDuration;

  &:hover {
    transform: scale(1.02);

    .card-draft .button {
      background-color: $brandPrimary;
    }

    .link {
      color: $white;
    }
  }

  &__content {
    width: 100%;
    cursor: pointer;
  }
}

.dialog-sale-card {
  .dialog__container {
    max-width: 50rem;
  }

  .dialog-wrapper__content {
    > :first-child {
      margin-bottom: 1rem;
    }
    margin-bottom: 1.5rem;
  }

  input {
    border: none;
    background-color: $stepperBackground;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  select {
    border: none;
    background-color: $stepperBackground;
  }
}

.sale-card-assigned-to {
  font-size: $miniSize;
  color: $fontGreyCard;
  display: flex;
  justify-content: center;
  align-items: center;

  &__point-separator {
    margin: 0 0.5rem;
    font-weight: $semiBold;
  }

  &__name {
    font-weight: $semiBold;
  }

  span {
    color: $brandSecondary;
  }
}

.card-draft {
  color: $fontGreyCard;

  .link {
    transition: color $defaultDuration;
  }

  &__buyer-seller {
    font-size: $largeSize;
    font-weight: $bold;
    text-transform: uppercase;
    line-height: $mediumLineHeight;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }

  .button {
    padding: 0 5rem;
    margin-bottom: 3rem;

    &:hover {
      background-color: $brandPrimary;
      color: $white;
    }
  }
}

.card-step {
  width: 100%;
  padding: 0 2rem;
  color: $fontColor;

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 8rem;
  }

  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    text-align: center;
    margin-bottom: 1rem;
    color: $white;
    background-color: $shade02;

    .alert-chip {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $lightBrandTertiary;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      top: 0rem;
      right: -1rem;
    }

    div:first-child {
      max-height: 3rem;
    }
  }
  &__icon--alert {
    background-color: $brandTertiary;
  }

  &__done-icon {
    color: $brandPrimary;
    width: 100%;
    margin: 1rem 0;

    div:first-child {
      width: 16rem;
    }
  }

  &__buyer-seller {
    font-size: $largeSize;
    font-weight: $bold;
    text-transform: uppercase;
    line-height: $mediumLineHeight;
    letter-spacing: 0.05ch;
    margin-bottom: 0.2rem;
  }

  &__district {
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0.06ch;
    margin-bottom: 1rem;
  }
}

.stepper {
  display: flex;
  width: 100%;
  height: 1.5rem;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  background-color: $stepperBackground;

  &__step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 0 1rem;
    font-size: 1rem;
    color: $white;
    background-color: $lightBrandTertiary;
    visibility: hidden;
  }
  &__step.valid {
    background-color: $brandPrimary;
  }
  &__step.on-border-left {
    border-radius: 1.5rem 0 0 1.5rem;
  }
  &__step.on-border-rigth {
    border-radius: 0 1.5rem 1.5rem 0;
  }
  &__step.active {
    visibility: visible;
  }
}
