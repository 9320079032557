.partner-agency-step {
  &, & + .step-actions {
    width: 100%;
    max-width: 36.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  margin-bottom: 1.5rem;
}
